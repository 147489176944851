<template>
  <!-- 정산/발행 >> Repair Restitution -->
  <div>
    <div class="content_box beforetab">
      <!-- content_box  -->
      <div id="repair-restitution-pop">
        <win-layer-pop>
          <component
            v-if="customComponent"
            :is="customComponent"
            @close="closePopup"
            :parent-info="popupParams"
          />
        </win-layer-pop>
      </div>
      <div id="layerReceipt">
        <win-layer-pop class="sample_popup_list">
          <component
            v-if="receiptComponent"
            :is="receiptComponent"
            @close="closeReceiptPopup"
            :parent-info="infoData"
          />
        </win-layer-pop>
      </div>
      <table class="tbl_search">
        <colgroup>
          <col width="180px"><col width="150px"><col width="259px"><col width="*"><col width="100px">
        </colgroup>
        <tbody>
          <tr>
            <td class="pr5">
              <select v-model="params.dtKnd">
                <option value="BL">{{ $t('msg.SETT010T010.039') }}</option> <!-- BL No -->
              </select>
            </td>
            <td colspan="2" class="pl0">
              <input type="text" style="width:200px" id="blNo" v-model.trim="params.blNo" @keyup.enter="searchData()">
            </td>
            <td class="text_right">
              <a class="button blue sh" @click="searchData()">{{ $t('msg.CMBA100.00120') }}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div><!-- cpntent_box // -->
    <div class="content_box mt10">
      <!-- content_box  -->
      <div class="flex_box">
        <span class="tot_num">Total : <span class="num">{{ total }}</span></span>
      </div>
      <div id="repair-realgrid" style="width: 100%; height: 450px"></div>
    </div><!-- content_box// -->
    <div class="mt10" style="float: right;">
      <button class="button blue lg mr5 disabled" id="receiptBtn" @click.prevent="printReceipt()">Receipt</button>
    </div>
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import JSZip from 'jszip'
import moment from 'moment'
import CalcIssue from '@/api/rest/settle/calcIssue'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import { rootComputed } from '@/store/helpers'
import importSvc from '@/api/rest/trans/importSvc'
import _lodash from 'lodash'
import store from '@/store/index'
import commons from '@/api/services/commons'
import doapi from '@/api/rest/trans/do'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  { fieldName: 'blNo', dataType: 'text' },
  { fieldName: 'reqRno', type: 'hidden' },
  { fieldName: 'reqDt', dataType: 'text' },
  { fieldName: 'cntrNo', dataType: 'text' },
  { fieldName: 'salNm', dataType: 'text' },
  { fieldName: 'finalAmtUsd', dataType: 'number' },
  { fieldName: 'wavStsCd', dataType: 'text' },
  { fieldName: 'wavStsCdNm', dataType: 'text' },
  { fieldName: 'apvStsCd', dataType: 'text' },
  { fieldName: 'apvStsNm', dataType: 'text' },
  { fieldName: 'bnkNm', dataType: 'text' },
  { fieldName: 'acctNo', dataType: 'text' },
  { fieldName: 'acctNm', dataType: 'text' },
  { fieldName: 'emlAddr', dataType: 'text' },
  { fieldName: 'reqUno', dataType: 'text' },
  { fieldName: 'reqTm', dataType: 'text' },
  { fieldName: 'apvUno', dataType: 'text' },
  { fieldName: 'apvDt', dataType: 'text' },
  { fieldName: 'apvTm', dataType: 'text' },
  { fieldName: 'fileNm', dataType: 'text' },
  { fieldName: 'fileCont', dataType: 'hidden' },
  { fieldName: 'rmk', dataType: 'text' },
  { fieldName: 'bkgNo', dataType: 'text' },
  { fieldName: 'payYn', dataType: 'text' },
  { fieldName: 'prtRvstNo', dataType: 'text' }
]

export const columns = [
  { name: 'reqDt', fieldName: 'reqDt', type: 'text', header: { text: 'Date' }, editable: false, width: 80 },
  { name: 'blNo', fieldName: 'blNo', type: 'text', header: { text: 'B/L No.' }, editable: false, width: 100 },
  { name: 'cntrNo', fieldName: 'cntrNo', type: 'text', header: { text: 'Container No' }, editable: false, width: 100 },
  { name: 'salNm', fieldName: 'salNm', type: 'text', header: { text: 'Customer Name' }, editable: false },
  { name: 'finalAmtUsd', fieldName: 'finalAmtUsd', numberFormat: '#,##0.00', type: 'number', header: { text: 'Amount' }, editable: false, width: 100 },
  { name: 'wavStsCdNm', fieldName: 'wavStsCdNm', type: 'text', header: { text: 'Status' }, editable: false, width: 80 },
  {
    name: 'bnkNm',
    fieldName: 'bnkNm',
    type: 'text',
    header: { text: 'Bank Name.' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const val = cell.value ? cell.value : ''
        const apvStsCd = provider.getValue(idx, 'apvStsCd')

        let str = '-'
        if (val === '') {
          str = `<span class="tbl_icon popup-icon" style="cursor:pointer;" onClick="vmApp.openPopup(${idx})"></span>`
        } else if (apvStsCd === '01') {
          str = `${val} <span class="tbl_icon popup-icon" style="cursor:pointer;" onClick="vmApp.openPopup(${idx})"></span>`
        } else {
          str = val
        }
        return str
      }
    }
  },
  {
    name: 'acctNo',
    fieldName: 'acctNo',
    type: 'text',
    header: { text: 'Account No.' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const val = cell.value ? cell.value : ''
        const apvStsCd = provider.getValue(idx, 'apvStsCd')

        let str = '-'
        if (val === '') {
          str = `<span class="tbl_icon popup-icon" style="cursor:pointer;" onClick="vmApp.openPopup(${idx})"></span>`
        } else if (apvStsCd === '01' || apvStsCd === '04') {
          str = `${val} <span class="tbl_icon popup-icon" style="cursor:pointer;" onClick="vmApp.openPopup(${idx})"></span>`
        } else {
          str = val
        }
        return str
      }
    },
    width: 100
  },
  {
    name: 'acctNm',
    fieldName: 'acctNm',
    type: 'text',
    header: { text: 'Account Name' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const val = cell.value ? cell.value : ''
        const apvStsCd = provider.getValue(idx, 'apvStsCd')

        let str = '-'
        if (val === '') {
          str = `<span class="tbl_icon popup-icon" style="cursor:pointer;" onClick="vmApp.openPopup(${idx})"></span>`
        } else if (apvStsCd === '01' || apvStsCd === '04') {
          str = `${val} <span class="tbl_icon popup-icon" style="cursor:pointer;" onClick="vmApp.openPopup(${idx})"></span>`
        } else {
          str = val
        }
        return str
      }
    },
    width: 80
  },
  {
    name: 'emlAddr',
    fieldName: 'emlAddr',
    type: 'text',
    header: { text: 'Email' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const val = cell.value ? cell.value : ''
        const apvStsCd = provider.getValue(idx, 'apvStsCd')

        let str = '-'
        if (val === '') {
          str = `<span class="tbl_icon popup-icon" style="cursor:pointer;" onClick="vmApp.openPopup(${idx})"></span>`
        } else if (apvStsCd === '01' || apvStsCd === '04') {
          str = `${val} <span class="tbl_icon popup-icon" style="cursor:pointer;" onClick="vmApp.openPopup(${idx})"></span>`
        } else {
          str = val
        }
        return str
      }
    }
  },
  {
    name: 'fileNm',
    fieldName: 'fileNm',
    type: 'text',
    header: { text: 'Upload Document' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const val = cell.value ? cell.value : ''
        const apvStsCd = provider.getValue(idx, 'apvStsCd')

        let str = '-'
        if (val === '') {
          str = `<span class="tbl_icon popup-icon" style="cursor:pointer;" onClick="vmApp.openPopup(${idx})"></span>`
        } else if (apvStsCd === '01' || apvStsCd === '04') {
          str = `${val} <span class="tbl_icon popup-icon" style="cursor:pointer;" onClick="vmApp.openPopup(${idx})"></span>`
        } else {
          str = val
        }
        return str
      }
    }
  },
  { name: 'apvStsNm', fieldName: 'apvStsNm', type: 'text', header: { text: 'Approval Status' }, editable: false, width: 80 },
  { name: 'rmk', fieldName: 'rmk', type: 'text', header: { text: 'Remarks' }, editable: false, width: 100 },
  {
    name: 'payYn',
    fieldName: 'payYn',
    type: 'text',
    header: { text: 'Pay Status' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const val = cell.value ? cell.value : ''

        if (val === 'Y') {
          return 'PAID'
        } else {
          return 'UNPAID'
        }
      }
    }
  }
]

export default {
  name: 'CalcIssueRepairRestitution',
  components: {
    CalcIssue,
    EBreadcrumbs,
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'repair-restitution-pop': () => import('@/pages/settle/popup/CalcIssueRepairRestitutionPop'),
    'container-return-pop': () => import('@/pages/pdf/popup/ContainerReturnPop'),
    CalcIssueReceiptPrintPop: () => import('@/pages/pdf/popup/CalcIssueReceiptPrintPop')
  },
  props: {
    paramData: {
      type: Object,
      default () {
        return {
          tab: '',
          keyword: '',
          dtKnd: '',
          eiCateCd: ''
        }
      }
    }
  },
  data: function () {
    return {
      // PDF 다운로드 팝업 컴포넌트
      customComponent: null,
      receiptComponent: null,
      infoData: {},
      popupParams: {},
      total: 0,
      dtType: {
        options: [
          { value: 'IV', text: this.$t('msg.SETT010T010.096') }
        ]
      },
      params: {
        dtKnd: 'BL'
      },
      isReceipt: false,
      receipt: null,
      popupReceiptParams: {
        CalcIssueReceiptPrintPop: {
          testUsrNo: '',
          testCtrCd: '',
          testAgtCd: '',
          items: [
            {
              rcvRno: '',
              prtRvstNo: '',
              cstNo: '',
              cstNm: ''
            }
          ]
        }
      }
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {

  },
  created () {
    console.log('App', app.$t('tit.SETTLE.TAB01.GRID_HEADER.001'))
    window.functionOutsideVue(this)
    window.settleAppTab7 = this
    this.userDmdCatCd = this.$store.state.settle.userDmdCatCd
  },
  mounted: function () {
    // this.setDateRange()
    //
    window.vmApp = this

    provider = new LocalDataProvider(true)
    gridView = new GridView('repair-realgrid')

    // 그리드 필드 생성
    provider.setFields(fields)

    // columns 명
    gridView.setColumns(columns)
    //gridView.setColumnLayout(gridLayout)
    gridView.setDataSource(provider)

    // 그리고 헤더 높이 조정
    gridView.header.heights = [40]
    gridView.displayOptions.rowHeight = 34
    gridView.displayOptions.fitStyle = 'none'
    gridView.displayOptions.selectionStyle = 'block'

    gridView.setFooter({ visible: false })
    gridView.setStateBar({ visible: false })
    gridView.setRowIndicator({ visible: false })
    //체크바 없애기
    gridView.setCheckBar({ visible: false })
   },
  methods: {
    openPopup (idx) {
      if (this.customComponent !== null) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose()
      }

      //content.title = 'Request Repair Restitution'

      let gridData = gridView.getJsonRows()
      vmApp.popupParams = gridData[idx]
      vmApp.popupParams.gridDate = gridData

      this.customComponent = 'repair-restitution-pop'
      //this.popupParams = content

      this.$ekmtcCommon.layerOpen('#repair-restitution-pop > .popup_dim')
    },
    async closePopup () {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('#repair-restitution-pop > .popup_dim')
      this.searchData()
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    async searchData () {
      if (this.params.blNo === '' || this.params.blNo === undefined) {
        this.$ekmtcCommon.showErrorTooltip('#blNo', this.$t('msg.CSBK100.147'))
        return
      } else {
        this.$ekmtcCommon.hideErrorTooltip('#blNo')
      }

      this.rstList = []
      this.rstFilter = []

      await CalcIssue.getRepairRestitutionList(this.params.blNo).then(response => {
        if (response.data.list !== '') {
          const resp = response.data.list
          this.rstList = resp
          this.rstFilter = resp
          this.total = this.rstList.length
          provider.setRows(this.rstList)

          if (this.rstList[0].payYn === 'Y') {
            $('#receiptBtn').removeClass('disabled')
          } else {
            $('#receiptBtn').addClass('disabled')
          }
        } else {
          this.total = 0
          provider.setRows(this.rstList)
          this.openAlert(this.$t('msg.SETT010T010.129'))
        }

        if (response.data.receipt !== null) {
          this.receipt = response.data.receipt
        }
      }).catch(err => {
          console.log(err)
      })
    },
    async printReceipt () {
      this.popupReceiptParams.CalcIssueReceiptPrintPop.items = []

      const initObj = {
        rcvRno: this.receipt.rcvRno,
        prtRvstNo: this.receipt.prtRvstNo,
        cstNo: this.memberDetail.cstCd,
        cstNm: this.receipt.cstNm,
        blNo: this.params.blNo
      }

      this.popupReceiptParams.CalcIssueReceiptPrintPop.items.push(initObj)

      this.openReceiptPopup('CalcIssueReceiptPrintPop')

      let blArr = []
      let voArr = []

      // GA dataLayer 추가
     /* window.dataLayer.push({
        event: 'set_popup_info',
        popup_booking_no: this.rstList[0].bkgNo,
        popup_bl_no: this.rstList[0].blNo
      })

      blArr.push({
        blNo: this.rstList[0].blNo,
        bkgNo: this.rstList[0].bkgNo,
        ownerCstNo: this.rstList[0].cneCstNo
      })

      const params = {
        blList: blArr
      }*/

      /*await importSvc.findAIreportReceipt(params).then(res => {
        // TODO : check test value
        // this.popupReceiptParams.CalcIssueReceiptPrintPop.testUsrNo = 'KONOIKE01'
        // this.popupReceiptParams.CalcIssueReceiptPrintPop.testCtrCd = 'ID'
        const resList = res.data

        if (resList.length === 0) {
          this.openAlert(this.$t('msg.DOIssue.027')) // 'Receipt 조회 대상 아닌 데이터 메세지 / Not Available Now.'
          return
        }

        for (const item of resList) {
          const initObj = {
            rcvRno: item.rcvRno,
            prtRvstNo: item.prtRvstNo,
            cstNo: this.memberDetail.cstCd,
            // cstNo: 'KTII00',
            cstNm: item.cstNm
          }

          this.popupReceiptParams.CalcIssueReceiptPrintPop.items.push(initObj)
        }
        this.openReceiptPopup('CalcIssueReceiptPrintPop')
      }).catch(err => {
        console.log(err)
      })*/
    },
    openReceiptPopup (compNm) {
      if (this.receiptComponent !== null) {
        this.receiptComponent = null
        this.$ekmtcCommon.layerClose()
      }
      this.infoData = this.popupReceiptParams[compNm]
      this.receiptComponent = compNm

      this.$ekmtcCommon.layerOpen('.sample_popup_list')
    },
    async closeReceiptPopup () {
      this.receiptComponent = null
      this.$ekmtcCommon.layerClose('.sample_popup_list')
    }
  }
}

</script>
<style>
.popup-icon { padding-right:18px; background: url(../../assets/images/common/icon_request_pop.png) no-repeat right 5px; float: right }

</style>
