<template>
  <!-- 정산/발행 >> 발행 -->
  <div>
    <div class="content_box beforetab">
      <!-- content_box  -->
      <div id="layerPop">
        <win-layer-pop>
          <component
            v-if="customComponent"
            :is="customComponent"
            :detail="detail"
            :ok-hidden="isHidden"
            :is-show-pop="isShowPop"
            :freight-bill-info="freightBillInfo"
            :parent-info="parentInfo"
            @callback="popCallback"
            @close="closePoup"
          />
        </win-layer-pop>
      </div>
      <div id="layerInvoice">
        <win-layer-pop>
          <component
            v-if="custCompInvoice"
            :is="custCompInvoice"
            :is-show-pop="isInvoiceShowPop"
            :parent-info="invoiceParam"
            @close="closeInvoce"
          />
        </win-layer-pop>
      </div>
      <win-layer-pop class="inv_ongoing_list_popup">
        <component
          v-if="popupComponent"
          :is="popupComponent"
          @close="closeInvPopup"
          @callback="popupCallback"
          :parent-info="popupParams"
        />
      </win-layer-pop>
      <win-layer-pop class="coupon_popup">
        <component
          v-if="couponComponent"
          :is="couponComponent"
          @close="closeCouponPopup"
          :parent-info="couponPopupParams"
        />
      </win-layer-pop>
      <div id="layerReceipt">
        <win-layer-pop class="sample_popup_list">
          <component
            v-if="receiptComponent"
            :is="receiptComponent"
            @close="closePopup"
            :parent-info="infoData"
          />
        </win-layer-pop>
      </div>

      <div id="layerInvoiceRequest">
        <win-layer-pop class="calc_issue_fare_regis_req_popup">
          <component
            v-if="popupTp2Component"
            :is="popupTp2Component"
            @close="closeTp2Popup"
            @callback="popupTp2Callback"
            :parent-info="popupTp2Params"
            @confirm="confirmTp2Popup"
          />
        </win-layer-pop>
      </div>
      <table class="tbl_search">
        <colgroup>
          <col width="180px"><col width="150px"><col width="259px"><col width="*"><col width="100px">
        </colgroup>
        <tbody>
          <tr>
            <td>
              <input type="radio" id="tab01-ex1" name="tab01-exim" v-model="params.eiCatCd" :value="'O'" @change="changeCalcEiCatCd()">
              <label for="tab01-ex1"><span></span>{{ $t('msg.SETT010T010.001') }}</label>

              <input type="radio" id="tab01-im1" name="tab01-exim" v-model="params.eiCatCd" :value="'I'" @change="changeCalcEiCatCd()">
              <label for="tab01-im1"><span></span>{{ $t('msg.SETT010T010.002') }}</label>
            </td>
            <td class="pr5">
              <select v-model="params.dtKnd">
                <option value="OD" v-show="params.eiCatCd === 'O'">{{ $t('msg.SETT010T010.003') }}</option>
                <option value="DD" v-show="params.eiCatCd === 'O'">{{ $t('msg.SETT010T010.004') }}</option>
                <option value="AD" v-show="params.eiCatCd === 'I'">{{ $t('msg.SETT010T010.005') }}</option> <!-- 입항일 -->
                <option value="BL">
                  <template v-if="params.eiCatCd === 'O'">
                    {{ $t('msg.SETT010T010.006') }} <!-- Booking & B/L No. -->
                  </template>
                  <template v-else>
                    {{ $t('msg.SETT010T010.039') }} <!-- B/L No. -->
                  </template>
                </option>
                <option value="IV">{{ $t('msg.SETT010T010.096') }}</option> <!-- Invoice No -->
              </select>
            </td>
            <td colspan="2" class="pl0">
              <div v-if="params.dtKnd === 'BL'">
                <input type="text" style="width:200px" v-model.trim="params.blNo" @keyup.enter="searchData" @input="params.blNo=params.blNo.toUpperCase()">
              </div>
              <div v-if="params.dtKnd === 'IV'">
                <input type="text" style="width:200px" v-model.trim="params.invNo" @keyup.enter="searchData" @input="params.blNo=params.blNo.toUpperCase()">
              </div>
              <div v-if="params.dtKnd !== 'BL' && params.dtKnd !== 'IV'">
                <e-date-range-picker
                  :id="'fromDtToDt'"
                  :term-type="termType"
                  :show-term-type="['01', '02', '03', '04']"
                  :sdate="range.sdate"
                  :edate="range.edate"
                  @change="changeDateRange"
                />
              </div>
            </td>
            <td class="text_right">
              <a class="button blue sh" @click="searchData()">{{ $t('msg.CMBA100.00120') }}</a>
            </td>
          </tr>
          <tr>
            <td></td>
            <td colspan="3">
              <span>

                <input type="radio" id="tab01-ship11" name="tab01-ship" :disabled="params.eiCatCd === 'I'" v-model="params.asProfileYn" :value="'Y'">
                <label for="tab01-ship11" class="mr10"><span></span>{{ $t('msg.SETT010T010.007') }}</label>

                <span class="position_relative mr20">
                  <button class="tbl_icon help" @click.prevent="helpClick('tip1')" @blur="helpClick('tip1')">help</button>
                  <div
                    id="tip1"
                    class="tooltip_wrap short position_absolute"
                    :style="isShowTip1 ? 'width:180px; display:block; left:0; top:24px;' : 'display:none;'"
                  ><!-- tooltip_wrap   -->
                    <div class="cont"><!-- cont -->
                      <button class="close" @click="helpClick('tip1')">close</button>
                      <ul class="bul_list_sm t2">
                        <li>
                          * {{ $t('msg.SETT010T010.008') }}
                        </li>
                      </ul>
                    </div><!-- cont -->
                  </div><!-- tooltip_wrap // -->
                </span>

                <input type="radio" id="tab01-ship12" name="tab01-ship" v-model="params.asProfileYn" :value="'N'">
                <label for="tab01-ship12" class="mr10"><span></span>{{ $t('msg.SETT010T010.009') }}</label>

                <span class="position_relative">
                  <button class="tbl_icon help" @click.prevent="helpClick('tip2')" @blur="helpClick('tip2')">help</button>
                  <div
                    id="tip2"
                    class="tooltip_wrap short position_absolute"
                    :style="isShowTip2 ? 'width:200px; display:block; left:0; top:24px;' : 'display:none;'"
                  ><!-- tooltip_wrap   -->
                    <div class="cont"><!-- cont -->
                      <button class="close" @click="helpClick('tip2')">close</button>
                      <ul class="bul_list_sm t2">
                        <li>
                          * {{ $t('msg.SETT010T010.010') }}
                        </li>
                      </ul>
                    </div><!-- cont -->
                  </div><!-- tooltip_wrap // -->
                </span>

              </span>

              <!-- <span class="ml20">
                <input type="checkbox" id="tab01-chk" name="tab01-chk" v-model="isCheckDmd" @change="changeDmd">
                <label for="tab01-chk"><span></span>계산적용여부</label>
              </span> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div><!-- cpntent_box // -->

    <div class="content_box mt10">
      <!-- content_box  -->

      <div class="flex_box">
        <span class="tot_num">Total : <span class="num">{{ total }}</span></span>
        <span class="input_box">
          <input class="wid300" type="text" :placeholder="$t('msg.SETT010T010.011')" v-model="templateStr" @keyup.prevent="changeFilter()">
          <!-- {{ templateStr }} -->
        </span>
        <span v-if="relYn" class="input_box">
          <select class="wid100" v-model="selPo">
            <option value="POL">Departure</option>
            <option value="POD">Arrival</option>
          </select>
        </span>
        <span v-if="relYn" class="input_box">
          <select class="wid200" v-model="selCtr" @change="checkSelectCtr()">
            <option value="">{{ $t('msg.ONEX010T010.004') }}<!-- 선택 --></option>
            <option v-for="ctrInfo in ctrCdList" :key="ctrInfo.ctrCd" :value="ctrInfo.ctrCd">{{ ctrInfo.ctrEnm }}</option>
          </select>
        </span>
        <span class="ml_auto cal_btn"><a class="button sm" href="#" @click="excelDownload">{{ $t('msg.MYIN020T010.016') }}</a></span>
      </div>
      <div id="realgrid" style="width: 100%; height: 450px">
      </div>

      <div class="clearfix">
        <div v-show="memberDetail.userCtrCd === 'KR'" class="float_left col_52p">
          <p class="txt_desc">
            <span class="font_medium">{{ $t('msg.SETT010T010.091') }}</span> <br>
            <span>
              - {{ $t('msg.SETT010T010.092') }}<br>
              - {{ $t('msg.SETT010T010.093') }}<br>
              - {{ $t('msg.SETT010T010.094') }}<br>
              - {{ $t('msg.SETT010T010.138') }}
            </span>
          </p>
        </div>
        <div class="col_48p">
          <!-- <div id="realgrid1" style="width: 100%; height: 200px"> -->
          <!-- <table class="tbl_col font_sm mt10">
            <colgroup>
              <col width="18%"><col width="16%"><col width="24%"><col width="20%"><col width="22%">
            </colgroup>
            <thead>
              <tr>
                <th>Kind</th>
                <th>CUR</th>
                <th>Invoice Amt.</th>
                <th>Tax</th>
                <th>Total Amt.</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="2">Freight</td>
                <td>USD</td>
                <td class="text_right">{{ $ekmtcCommon.changeNumberFormat(sumMap.fuAmt, { isComma: true }) }}</td>
                <td class="text_right">-</td>
                <td class="text_right">{{ $ekmtcCommon.changeNumberFormat(sumMap.fuTot, { isComma: true }) }}</td>
              </tr>
              <tr>
                <td class="border_left">KRW</td>
                <td class="text_right">{{ $ekmtcCommon.changeNumberFormat(sumMap.fkAmt, { isComma: true }) }}</td>
                <td class="text_right">-</td>
                <td class="text_right">{{ $ekmtcCommon.changeNumberFormat(sumMap.fkTot, { isComma: true }) }}</td>
              </tr>

              <tr>
                <td>Other</td>
                <td>KRW</td>
                <td class="text_right">{{ $ekmtcCommon.changeNumberFormat(sumMap.okAmt, { isComma: true }) }}</td>
                <td class="text_right">{{ $ekmtcCommon.changeNumberFormat(sumMap.okTax, { isComma: true }) }}</td>
                <td class="text_right">{{ $ekmtcCommon.changeNumberFormat(sumMap.okTot, { isComma: true }) }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th rowspan="2" class="title">TOTAL</th>
                <th class="title">USD</th>
                <th colspan="3" class="text_right">{{ $ekmtcCommon.changeNumberFormat(sumMap.tuTot, { isComma: true }) }}</th>
              </tr>
              <tr>
                <th class="title border_left">KRW</th>
                <th colspan="3" class="text_right">{{ $ekmtcCommon.changeNumberFormat(sumMap.tkTot, { isComma: true }) }}</th>
              </tr>
            </tfoot>
          </table> -->
        </div>
      </div>
    </div><!-- content_box// -->
    <div class="flex_box mt10">
      <!-- <a class="button blue lg ml_auto mr5" v-b-modal.fright-modal-sm>Message Test</a> -->
      <!-- <a class="button blue lg ml_auto mr5" @click.prevent="onInvoiceCancel">Invoice Cancel</a> -->
      <!-- <a class="button blue lg mr5" @click="openPopup('FreightBillPop', 'FreightBillPop')">{{ $t('msg.SETT010T010.013') }}</a> -->
      <!-- <a v-show="params.eiCatCd === 'O'" class="button blue lg mr5" href="#" @click.prevent="openPopup('BLPaymentPop', 'BL')">{{ $t('msg.SETT010T010.017') }}</a> -->
      <!-- <a v-show="params.eiCatCd === 'I'" class="button blue lg" href="#" @click.prevent="openPopup('DOPaymentPop', 'DO')">{{ $t('msg.SETT010T010.018') }}</a> -->
      <!-- <a v-show="params.eiCatCd === 'I' " class="button blue lg mr5" href="#">D/O Request</a>-->
      <!-- <a v-show="params.eiCatCd === 'I' " class="button blue lg mr5" href="#" @click.prevent="openPopup('PaymentRegPop', 'PaymentRegPop')">D/O Payment</a>-->
      <a v-show="memberDetail.userCtrCd === 'KR' && params.eiCatCd === 'O'" class="button blue lg ml_auto mr5" @click="fnAction('SRD')">서렌더피</a>
      <a :class="[memberDetail.userCtrCd === 'KR' && params.eiCatCd === 'O'? 'button blue lg mr5' : 'button blue lg ml_auto mr5']" @click="openFreetimeTab">Freetime</a>
      <a class="button blue lg mr5" @click="openInvoice2('btn')">{{ $t('msg.SETT010T010.012') }}</a>
      <a v-show="memberDetail.userCtrCd === 'KR' && params.eiCatCd === 'O'" class="button blue lg mr5" @click="openPopup('FreightBillPop', 'TaxInvoicePop')">{{ $t('msg.SETT010T010.014') }}</a>
      <a v-show="params.eiCatCd === 'O' && ['ID'].indexOf(memberDetail.userCtrCd) >= 0" class="button blue lg mr5" @click.prevent="printReceipt()">Receipt</a>
      <a v-show="params.eiCatCd === 'O'" class="button blue lg mr5" @click="requestPop()">{{ $t('msg.SETT010T010.015') }}</a>
      <a v-show="params.eiCatCd === 'I' && ['ID'].indexOf(memberDetail.userCtrCd) >= 0" class="button blue lg mr5" @click.prevent="printReceipt()">Receipt</a>
      <a class="button blue lg mr5" @click="openBlCopyPop()">{{ $t('msg.SETT010T010.016') }}</a>
      <a v-show="params.eiCatCd === 'O'" class="button blue lg mr5" @click.prevent="fnAction('BL_ISSUE')">
        <template v-if="memberDetail.userCtrCd === 'IN'">
          B/L Issue Request
        </template>
        <template v-else>
          {{ $t('msg.SETT010T010.017') }}
        </template>
      </a>

      <a v-show="params.eiCatCd === 'I' && ['KR', 'ID'].indexOf(memberDetail.userCtrCd) >= 0" class="button blue lg mr5" @click.prevent="fnAction('DO_PRINT')">{{ $t('msg.ONEX010T010.060') }} {{ $t('msg.MYIN040G010.029') }}</a>

      <template v-if="params.eiCatCd === 'I'">
        <a v-if="memberDetail.userCtrCd === 'KR'" class="button blue lg mr5" @click.prevent="fnAction('DO_ISSUE')">{{ $t('msg.SETT010T010.018') }}</a>
        <!-- <a v-show="params.eiCatCd === 'I' && ['ID'].indexOf(memberDetail.userCtrCd) >= 0" class="button blue lg mr5" @click.prevent="doRequest()">D/O Request</a> -->
        <a v-else class="button blue lg mr5" @click.prevent="handleOnDoReqBtnClick()">{{ $t('msg.MYIN050T010.084') }}</a>
      </template>
    </div>
    <common-unsolved ref="commonUnsolved" @callback="searchData" />
    <invoice-publish-pop v-if="isInvoicePublishPop" ref="invoicePub" :invoice-data="invoiceData" :as-ei-cat-cd="params.eiCatCd" />
    <b-modal
      id="fright-modal-sm"
      style="display: block;"
      size="sm"
      title="Test"
      footer-class="p-0 m-0 modal-footer1"
      hide-header
      hide-footer
      no-close-on-backdrop
      centered
    >
      <div class="modal-txt-box">
        Hello Small Modal!
      </div>
      <div>
        <b-row class="m-0">
          <b-col cols="6" class="p-0">
            <b-button
              class="m-0"
              style="color: #ffffff; border: solid 1px #0641b8; background-color: #0641b8;"
              squared block
              :size="'sm'"
              :variant="'primary'"
              @click="msgBoxTest"
            >
              확인
            </b-button>
          </b-col>
          <b-col cols="6" class="p-0">
            <b-button class="m-0" squared block :size="'sm'" :variant="'secondary'">Cancel</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<style scoped>
  .modal-txt-box {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    height: 80px;
    padding: 10px;
  }
  .modal-body {
    padding: 0 !important;
  }
  /* #fright-modal-sm .modal-content {
    min-height: 300px !important;
  } */
  #fright-modal-sm .modal-content .modal-footer1 {
    display: block !important;
  }
  .text-column {
    text-align: left;
  }
  .number-column {
    text-align: right;
  }
  span .bl-link {
    color: #075bb9;
    text-decoration: underline !important;
    cursor: pointer;
  }

  .issststus {
    /* color: black; */
    text-decoration: underline !important;
  }
</style>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import JSZip from 'jszip'
import moment from 'moment'
import CalcIssue from '@/api/rest/settle/calcIssue'
import importSvc from '@/api/rest/trans/importSvc'
import exportSvc from '@/api/rest/trans/exportSvc'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import CalcIssueList from '@/pages/settle/CalcIssueList'
import EDateRangePickerForSettle from '@/components/common/EDateRangePickerForSettle'
import FreightBillPop from '@/components/common/FreightBillPop'
import InvoicePublishPop from '@/pages/settle/popup/InvoicePublishPop' // Invoice발행 및 출력 공통
import { rootComputed } from '@/store/helpers'
import _lodash from 'lodash'
import store from '@/store/index'
import searchDemurrageDetailFormApi from '@/api/rest/settle/searchdemurrageDetailForm'
import demInvoicePop from '@/pages/settle/popup/DemInvoicePop'
import commons from '@/api/services/commons'
import PaymentRegPop from '@/pages/trans/popup/PaymentRegPop'
import payment from '@/api/rest/trans/payment'
import doapi from '@/api/rest/trans/do'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  { fieldName: 'bkgNo', dataType: 'text' },
  { fieldName: 'blNo', dataType: 'text' },
  { fieldName: 'ownerCstNo', dataType: 'text' },
  { fieldName: 'actShprCstEnm', dataType: 'text' },
  { fieldName: 'polPortNm', dataType: 'text' },
  { fieldName: 'podPortNm', dataType: 'text' },
  { fieldName: 'voyNo', dataType: 'hidden' },
  { fieldName: 'arvVoyNo', dataType: 'hidden' },
  { fieldName: 'vslNm', dataType: 'text' },
  { fieldName: 'depVslNm', dataType: 'text' },
  { fieldName: 'arvVslNm', dataType: 'text' },
  { fieldName: 'cntrCnt', dataType: 'text' },
  // { fieldName: 'agencyTaskCnt', dataType: 'number' },
  { fieldName: 'frtCd', dataType: 'text' },
  { fieldName: 'demDetCatCd', dataType: 'text' },
  { fieldName: 'occrCurCd', type: 'hidden' },
  { fieldName: 'occrAmt', dataType: 'number' },
  { fieldName: 'invoiceBtn', dataType: 'text' },
  { fieldName: 'exRate', dataType: 'number' },
  { fieldName: 'payCtrCd', dataType: 'text' },
  { fieldName: 'apoExchRt', dataType: 'number' },
  { fieldName: 'issStstus', dataType: 'text' },
  { fieldName: 'blStatus', dataType: 'text' },
  // { fieldName: 'blStatusCd', dataType: 'text' },
  { fieldName: 'remarkCd', dataType: 'text' },
  { fieldName: 'remark', dataType: 'text' },
  { fieldName: 'invIssNo', dataType: 'text' },
  { fieldName: 'invRno', dataType: 'text' },
  { fieldName: 'invRno1', dataType: 'text' },
  { fieldName: 'invRnoSrd', dataType: 'text' },
  { fieldName: 'reqCnt', dataType: 'number' },
  { fieldName: 'reqCnt2', dataType: 'number' },
  { fieldName: 'reqCnt3', dataType: 'number' },
  { fieldName: 'taxApvStsCd', dataType: 'text' },
  { fieldName: 'outAmt', dataType: 'number' },
  { fieldName: 'eiCatCd', dataType: 'text' },
  { fieldName: 'bkgShprCstNo', dataType: 'text' },
  { fieldName: 'obrdDt', dataType: 'text' },
  { fieldName: 'doStCd', dataType: 'text' },
  { fieldName: 'blStText', dataType: 'text' },
  { fieldName: 'blStCd', dataType: 'text' },
  { fieldName: 'loiReqCatCd', dataType: 'text' },
  { fieldName: 'loiEtcRmk', dataType: 'text' },
  { fieldName: 'loiTelNo', dataType: 'text' },
  { fieldName: 'cntrCfsYn', dataType: 'text' },
  { fieldName: 'certiYn', dataType: 'text' },
  { fieldName: 'elYn', dataType: 'text' },
  { fieldName: 'rejectCont', dataType: 'text' },
  { fieldName: 'cntrStsCd', dataType: 'text' },
  { fieldName: 'oblNm', dataType: 'text' },
  { fieldName: 'surNm', dataType: 'text' },
  { fieldName: 'wayNm', dataType: 'text' },
  { fieldName: 'rejectNm', dataType: 'text' },
  { fieldName: 'oblUno', dataType: 'text' },
  { fieldName: 'surUno', dataType: 'text' },
  { fieldName: 'wayUno', dataType: 'text' },
  { fieldName: 'rejectUno', dataType: 'text' },
  { fieldName: 'oblTel', dataType: 'text' },
  { fieldName: 'surTel', dataType: 'text' },
  { fieldName: 'wayTel', dataType: 'text' },
  { fieldName: 'rejectTel', dataType: 'text' },
  { fieldName: 'oblArea', dataType: 'text' },
  { fieldName: 'surArea', dataType: 'text' },
  { fieldName: 'wayArea', dataType: 'text' },
  { fieldName: 'rejectArea', dataType: 'text' },
  { fieldName: 'oblAreaNm', dataType: 'text' },
  { fieldName: 'surAreaNm', dataType: 'text' },
  { fieldName: 'wayAreaNm', dataType: 'text' },
  { fieldName: 'rejectAreaNm', dataType: 'text' },
  { fieldName: 'blPcupNo', dataType: 'text' },
  { fieldName: 'blPcupDt', dataType: 'text' },
  { fieldName: 'blErrCd', dataType: 'text' },
  { fieldName: 'frtYn', dataType: 'text' },
  { fieldName: 'payNo', dataType: 'text' },
  { fieldName: 'evdcmtrUpldYn', dataType: 'text' },
  { fieldName: 'payStsCd', dataType: 'text' },
  { fieldName: 'frtInfo', dataType: 'text' },
  { fieldName: 'frtPncCd', dataType: 'text' },
  { fieldName: 'payDoStCd', dataType: 'text' },
  { fieldName: 'fwdrCstNo', dataType: 'text' },
  { fieldName: 'isFwdrYn', dataType: 'text' },
  { fieldName: 'payRejectCont', dataType: 'text' },
  { fieldName: 'frtPncCdDo', dataType: 'text' },
  { fieldName: 'authImBl', dataType: 'text' },
  { fieldName: 'authImInv', dataType: 'text' },
  { fieldName: 'authImDo', dataType: 'text' },
  { fieldName: 'podCtrCd', dataType: 'text' },
  { fieldName: 'polCtrCd', dataType: 'text' },
  { fieldName: 'oblContent', dataType: 'text' },
  { fieldName: 'doApvStsCd', dataType: 'text' },
  { fieldName: 'doRejectCont', dataType: 'text' },
  { fieldName: 'doApprNm', dataType: 'text' },
  { fieldName: 'doApprUno', dataType: 'text' },
  { fieldName: 'doApprTel', dataType: 'text' },
  { fieldName: 'doApprArea', dataType: 'text' },
  { fieldName: 'polPortCd', dataType: 'text' },
  { fieldName: 'podPortCd', dataType: 'text' },
  { fieldName: 'hcneCstNo', dataType: 'text' },
  { fieldName: 'doStsCd', dataType: 'text' },
  { fieldName: 'payPendingCont', dataType: 'text' },
  { fieldName: 'blStsCd', dataType: 'text' }
]

export const columns = [
  { name: 'bkgNo', fieldName: 'bkgNo', type: 'text', header: { text: 'BKG No.' }, editable: false, width: 80 },
  {
    name: 'blNo',
    fieldName: 'blNo',
    type: 'text',
    header: { text: app.$t('msg.SETT010T010.137') },
    editable: false,
    width: 110,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const blNo = cell.value
        const idx = cell.index.dataRow
        // const eiCatCd = grid.getValue(idx, 'eiCatCd')
        const eiCatCd = provider.getValue(idx, 'eiCatCd')
        let str = blNo
        if (eiCatCd === 'O') {
          // str = `<a class="link blue" onclick="seetleAppTab1.goDetailPageAtGrid(event, ${idx})">${blNo}</a>`
          str = `<span>${blNo}</span>`
        }
        return str
      }
    }
  },
  { name: 'ownerCstNo', fieldName: 'ownerCstNo', type: 'text', header: { text: 'ownerCstNo' }, editable: false, width: 80 },
  { name: 'actShprCstEnm', fieldName: 'actShprCstEnm', type: 'text', header: { text: app.$t('tit.SETTLE.TAB01.GRID_HEADER.001') }, editable: false, width: 180 },
  { name: 'polPortNm', fieldName: 'polPortNm', type: 'text', header: { text: app.$t('tit.SETTLE.TAB01.GRID_HEADER.002') }, editable: false, width: 100 },
  { name: 'podPortNm', fieldName: 'podPortNm', type: 'data', header: { text: app.$t('tit.SETTLE.TAB01.GRID_HEADER.003') }, editable: false, width: 100 },
  { name: 'voyNo', fieldName: 'voyNo', type: 'hidden', header: { text: 'voyNo' }, editable: false, visible: false },
  { name: 'arvVoyNo', fieldName: 'arvVoyNo', type: 'hidden', header: { text: 'arvVoyNo' }, editable: false, visible: false },
  {
    name: 'vslNm',
    fieldName: 'vslNm',
    type: 'text',
    header: { text: app.$t('tit.SETTLE.TAB01.GRID_HEADER.004') },
    editable: false,
    width: 180,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const data = cell.value
        const idx = cell.index.dataRow
        // const voyNo = grid.getValue(idx, 'voyNo') === null ? '' : grid.getValue(idx, 'voyNo')
        const voyNo = provider.getValue(idx, 'voyNo') === null ? '' : provider.getValue(idx, 'voyNo')
        const str = data + ' / ' + voyNo

        return str
      }
    }
  },
  {
    name: 'depVslNm',
    fieldName: 'depVslNm',
    type: 'text',
    header: { text: app.$t('tit.SETTLE.TAB01.GRID_HEADER.005'), styleName: 'multi-line-css' },
    editable: false,
    visible: false,
    width: 100,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const data = cell.value
        const idx = cell.index.dataRow
        //const voyNo = grid.getValue(idx, 'voyNo') === null ? '' : grid.getValue(idx, 'voyNo')
        const voyNo = provider.getValue(idx, 'voyNo') === null ? '' : provider.getValue(idx, 'voyNo')
        const vslNm = provider.getValue(idx, 'vslNm') === null ? '' : provider.getValue(idx, 'vslNm')
        const str = vslNm + ' / ' + voyNo

        return str
      }
    }
  },
  {
    name: 'arvVslNm',
    fieldName: 'arvVslNm',
    type: 'text',
    header: { text: app.$t('tit.SETTLE.TAB01.GRID_HEADER.006'), styleName: 'multi-line-css' },
    editable: false,
    visible: false,
    width: 100,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const data = cell.value
        const idx = cell.index.dataRow
        // const voyNo = grid.getValue(idx, 'voyNo') === null ? '' : grid.getValue(idx, 'voyNo')
        const voyNo = provider.getValue(idx, 'arvVoyNo') === null ? '' : provider.getValue(idx, 'arvVoyNo')
        const str = data + ' / ' + voyNo

        return str
      }
    }
  },
  { name: 'cntrCnt', fieldName: 'cntrCnt', type: 'text', header: { text: 'Container' }, editable: false, width: 70 },
  // { name: 'agencyTaskCnt', fieldName: 'cnagencyTaskCnttrCnt', type: 'number', header: { text: 'agencyTaskCnt' }, editable: false },
  { name: 'frtCd', fieldName: 'frtCd', type: 'text', header: { text: 'Kind' }, editable: false, width: 70 },
  { name: 'demDetCatCd', fieldName: 'demDetCatCd', type: 'text', header: { text: 'demDetCatCd' }, editable: false },
  { name: 'occrCurCd', fieldName: 'occrCurCd', type: 'hidden', header: { text: 'occrCurCd' }, editable: false, visible: false },
  {
    name: 'occrAmt',
    fieldName: 'occrAmt',
    type: 'number',
    header: { text: 'Amount' },
    editable: false,
    width: 80,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        let str = '<div style="display: block; text-align: right; padding-right: 16px;">-</div>'
        const data = cell.value
        if (data !== 0) {
          const idx = cell.index.dataRow
          /*
          const occrCurCd = grid.getValue(idx, 'occrCurCd')
          const invIssNo = grid.getValue(idx, 'invIssNo') === null ? '' : grid.getValue(idx, 'invIssNo')
          const strInvIssNo = _lodash.trim(invIssNo)
          const eiCatCd = grid.getValue(idx, 'eiCatCd')
          */
          const occrCurCd = provider.getValue(idx, 'occrCurCd')
          const invIssNo = provider.getValue(idx, 'invIssNo') === null ? '' : provider.getValue(idx, 'invIssNo')
          const strInvIssNo = _lodash.trim(invIssNo)
          const eiCatCd = provider.getValue(idx, 'eiCatCd')

          let occrAmt
          if (occrCurCd === 'KRW') {
            occrAmt = new Intl.NumberFormat().format(data)
          } else {
            // occrAmt = new Intl.NumberFormat().format(data)
            occrAmt = new Intl.NumberFormat('en-US', {
                            style: 'decimal',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                            currency: occrCurCd
                          }).format(data)
          }
          // Invoice 발행은 수출, 수입, 발행여부 상관없이 무조건 보여지도록. 발행되지 않았다면 발행하도록 수정.
          let clickEvt = ''
          let clickCss = ''
          // 인도를 제외한 국가만 Click시 invoice 팝업
          if (vmApp.memberDetail.userCtrCd !== 'IN') {
            clickEvt = `onClick="seetleAppTab1.getInvoice(event, ${idx})"`
            clickCss = 'class="link"'
          }
          str = `<div style="display: block; text-align: right; cursor:pointer;"><a ${clickCss} ${clickEvt}>${occrCurCd} ${occrAmt}</a></div>`
        }
        return str
      }
    }
  },
  {
    name: 'invoiceBtn',
    fieldName: 'invoiceBtn',
    type: 'text',
    header: { text: 'Invoice' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const data = cell.value
        const idx = cell.index.dataRow
        // const invIssNo = grid.getValue(idx, 'invIssNo') === null ? '' : grid.getValue(idx, 'invIssNo')
        const invIssNo = provider.getValue(idx, 'invIssNo') === null ? '' : provider.getValue(idx, 'invIssNo')
        const strInvIssNo = _lodash.trim(invIssNo)
        const invRno = provider.getValue(idx, 'invRno')
        const reqCnt = '' + provider.getValue(idx, 'reqCnt')
        const reqCnt2 = '' + provider.getValue(idx, 'reqCnt2')
        const reqCnt3 = '' + provider.getValue(idx, 'reqCnt3')
        const blNo = '' + provider.getValue(idx, 'blNo')
        const invRnoSrd = provider.getValue(idx, 'invRnoSrd')

        // console.log(reqCnt, reqCnt2, reqCnt3)
        let str = '-'
        // 인도를 제외한 국가만 Click시 invoice 팝업
        // 2022.06.16 해외 전체 제외 하고 Click시 invoice 팝업 변경
        // 2022.06.30 국가에 상관없이 INVOICE 아이콘 표시하도록 변경 (dsyang)
        //if (vmApp.memberDetail.userCtrCd === 'KR') {
        if (vmApp.memberDetail.userCtrCd === 'IN') {
          if (!vmApp.$ekmtcCommon.isEmpty(invRno)) {
            if (strInvIssNo !== '') {
              str = `<span class="tbl_icon invoice" style="cursor:pointer;" title="${strInvIssNo}" onClick="seetleAppTab1.openInvoice2('grid', ${idx})"></span>`
            } else {
              str = ''
            }
          }
        } else {
          if (reqCnt2 !== '0') {
            str = app.$t('msg.CMBA380.035') // 처리중
          } else if (reqCnt3 !== '0') {
            str = app.$t('msg.CMBA380.038') // 거절
          } else if ((invRnoSrd === 0 || vmApp.$ekmtcCommon.isEmpty(invRnoSrd)) && reqCnt === '0') {
            str = `<div style="display: block; cursor:pointer;"><a class="link" onclick="vmAppFunc.invoiceRequest('${blNo}', '${idx}')">` + app.$t('msg.SETT060G010.001') + '</a></div>' // 요청
          } else if (vmApp.$ekmtcCommon.isEmpty(invRno) && reqCnt === '0') {
            // str = `<a onclick="seetleAppTab1.getRejectCont(event, '${idx}','${val}')">` + rtnMsg + '</a>'
            str = `<div style="display: block; cursor:pointer;"><a class="link" onclick="vmAppFunc.invoiceRequest('${blNo}', '${idx}')">` + app.$t('msg.SETT060G010.001') + '</a></div>' // 요청
          } else if (vmApp.$ekmtcCommon.isEmpty(invRno) && reqCnt !== '0') {
            str = app.$t('msg.CMBA380.035') // 처리중
          } else if (!vmApp.$ekmtcCommon.isEmpty(invRno)) {
            if (strInvIssNo !== '') {
              str = `<span class="tbl_icon invoice" style="cursor:pointer;" title="${strInvIssNo}" onClick="seetleAppTab1.getInvoice(event, ${idx})"></span>`
            }
          }
        }
        //}
        return str
      }
    },
    width: 60
  },
  {
    name: 'exRate',
    fieldName: 'exRate',
    type: 'number',
    header: { text: app.$t('msg.SETT010T010.134') },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        let str
        const idx = cell.index.dataRow
        /*
        const frtCd = grid.getValue(idx, 'frtCd')
        const exRate = cell.value
        const apoExchRt = grid.getValue(idx, 'apoExchRt')
        const eiCatCd = grid.getValue(idx, 'eiCatCd')
        const occrCurCd = grid.getValue(idx, 'occrCurCd')
        const tmpExRate = new Intl.NumberFormat('en-US', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                      currency: 'USD'
                    }).format(exRate)
        const strExRate = exRate === 0 || exRate === 1 ? '-' : '' + tmpExRate
        const invIssNo = grid.getValue(idx, 'invIssNo')
        const strInvIssNo = _lodash.trim(invIssNo)
        */
        const frtCd = provider.getValue(idx, 'frtCd')
        const exRate = cell.value
        const apoExchRt = provider.getValue(idx, 'apoExchRt')
        const eiCatCd = provider.getValue(idx, 'eiCatCd')
        const occrCurCd = provider.getValue(idx, 'occrCurCd')
        const tmpExRate = new Intl.NumberFormat('en-US', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                      currency: 'USD'
                    }).format(exRate)
        const strExRate = exRate === 0 || exRate === 1 ? '-' : '' + tmpExRate
        const invIssNo = provider.getValue(idx, 'invIssNo')
        const strInvIssNo = _lodash.trim(invIssNo)

        str = `<div style="display: block; text-align: right; padding-right: 8px;"><span>${strExRate}</span></div>`

        if (frtCd === 'Freight' && seetleAppTab1.memberDetail.userCtrCd === 'KR' && eiCatCd === 'I' && occrCurCd === 'USD') { // 한국 + 수입 일때
          if (strInvIssNo !== '' && exRate !== apoExchRt) {
            str = `<div style="display: block; text-align: right; padding-right: 8px;" onClick="seetleAppTab1.onClickCellExRate(event, ${idx})"><span style="color: red;">${strExRate}</span></div>`
          }
        }

        return str
      }
    }
  },
  { name: 'payCtrCd', fieldName: 'payCtrCd', type: 'text', header: { text: 'payCtrCd' }, editable: false },
  { name: 'apoExchRt', fieldName: 'apoExchRt', type: 'number', header: { text: 'apoExchRt' }, editable: false },
  //{ name: 'issStstus', fieldName: 'issStstus', type: 'text', header: { text: app.$t('msg.SETT010T010.135') }, editable: false }, // 계산서발행
  {
    name: 'issStstus',
    fieldName: 'issStstus',
    type: 'text',
    header: { text: app.$t('msg.SETT010T010.135') }, //계산서발행
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const val = cell.value
        const idx = cell.index.dataRow
        // const bkgNo = grid.getValue(idx, 'bkgNo')
        const bkgNo = provider.getValue(idx, 'bkgNo')
        // const blNo = grid.getValue(idx, 'blNo') || ''
        const blNo = provider.getValue(idx, 'blNo') || ''
        // const eiCatCd = grid.getValue(idx, 'eiCatCd')
        const eiCatCd = provider.getValue(idx, 'eiCatCd')
        // const remark = grid.getValue(idx, 'remark')
        const remark = provider.getValue(idx, 'remark')
        const id = bkgNo + '_' + blNo

        let title = ''
        let str = ''

        if (eiCatCd === 'O') {
          let rtnMsg = ''

          if (val === 'Paid' || val === 'Y' || val === '03') {
            title = app.$t('msg.ONEX010T010.131') // 완료
            // str = `<a onclick="seetleAppTab1.getBlPaidCont(event, '' , '${blNo}', '${id}')">` + val + '</a>'
            str = '<a>' + val + '</a>'
          } else {
              if (val === 'N') {
                str = 'N' //'세금계산서 발행 요청전'
                return str
              } else if (val === '04') { // 거절
                rtnMsg = app.$t('msg.CMBA380.038')
              } else if (val === '02') { // 발행중
                str = app.$t('art.CMATK325')
                return str
              } else if (val === '07') { // Pending
                rtnMsg = 'Pending'
              }

              str = `<a onclick="seetleAppTab1.getRejectCont(event, '${idx}','${val}')">` + rtnMsg + '</a>'
              // str = '<a>' + rtnMsg + '</a>'
          }

          return str
        } else { // 수입일때,
            // const frtInfo = grid.getValue(idx, 'frtInfo')
            const frtInfo = provider.getValue(idx, 'frtInfo')
            // const payStsCd = grid.getValue(idx, 'payStsCd')
            const payStsCd = provider.getValue(idx, 'payStsCd')
            // const payNo = grid.getValue(idx, 'payNo')
            const payNo = provider.getValue(idx, 'payNo')
            // const evdcmtrUpldYn = grid.getValue(idx, 'evdcmtrUpldYn')
            const evdcmtrUpldYn = provider.getValue(idx, 'evdcmtrUpldYn')

            let cssClasss = ''
            let btnText = ''
            let frtInfoArr = []
            let rtnStr = ''
            let stsMsg = ''

            if (frtInfo !== undefined) {
              frtInfoArr = frtInfo.split('/')
            }
            const arrTooltip = []
            const arrPayInfo = []

            if (vmApp.memberDetail.userCtrCd === 'ID') {
              // 인도네시아
              if (payStsCd === '02') {
                rtnStr = ''
              } else {
                rtnStr = ''
              }
              if (remark !== undefined) {
                arrTooltip.push(remark)
              }
              if (payNo !== undefined) {
                // arrPayInfo.push(`<p class="color_blue2 font_11 mt5">Payment No. ('${evdcmtrUpldYn}')</p>`)
                arrPayInfo.push(`${evdcmtrUpldYn}`)
              } else {
                arrPayInfo.push(' ')
              }

              if (arrTooltip.length !== 0) {
                rtnStr = '<span class="">' + val + '</span>'

                return rtnStr
              } else {
                // `<button type="button" id="frtYn_btn_${id}" class="status_icon ${cssClasss}">${btnText}</button>${arrPayInfo.join('')}`
              }
            } else {
              /* the other contries */
              if ((val === 'Paid' || val === 'Y' || val === '03') && frtInfo !== undefined) {
                rtnStr = '<span class="">' + val + '</span>'
              } else {
                if (val === 'N') {
                  rtnStr = 'N' //'세금계산서 발행 요청전'
                  return rtnStr
                } else if (val === '04') { // 거절
                  stsMsg = app.$t('msg.CMBA380.038')
                } else if (val === '02') { // 발행중
                  rtnStr = app.$t('art.CMATK325')
                  return rtnStr
                } else if (val === '07') { // Pending
                  stsMsg = 'Pending'
                }
                rtnStr = `<a class="issststus" onclick="seetleAppTab1.getRejectCont(event, '${idx}', '${val}')">` + stsMsg + '</a>'
                // rtnStr = '<span class="">' + stsMsg + '</span>'
              }
                return rtnStr
            }
        }
      }
    },
    width: 50
  },
  {
    name: 'blStatus',
    fieldName: 'blStatus',
    type: 'text',
    header: { text: 'Status' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const data = cell.value
        let str = ''
        if (data === 'Issued') {
          str = '<span class="label gray md minwid60">' + data + '</span>'
        } else if (data === 'Requested') {
          str = '<span class="label gray md minwid70">' + data + '</span>'
        } else if (data === '발급가능') {
          str = '<span class="label green md minwid60">' + data + '</span>'
        } else if (data === '성공(국세청)') {
          str = '<span class="label green md minwid70">' + data + '</span>'
        } else if (data === '발급불가') {
          str = '<span class="label red md minwid60">' + data + '</span>'
        } else {
          str = '<span class="label red md minwid70">' + data + '</span>'
        }

        return str
      }
    }
  },
  {
    name: 'payNo',
    fieldName: 'payNo',
    type: 'text',
    header: { text: 'Pay No' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const data = cell.value
        const idx = cell.index.dataRow
        const payStsCd = provider.getValue(idx, 'payStsCd')

        let str = ''

        if (data !== undefined && data !== null && data !== '') {
          for (let i = 0; i < data.split('|').length; i++) {
            let payNoData = data.split('|')[i]
            let payStsCdData = ''

            if (payStsCd !== undefined && payStsCd !== null && payStsCd !== '' && payStsCd.split('|').length > i) {
              payStsCdData = payStsCd.split('|')[i]
            }

            if (str !== '') {
              str += '<br />'
            }

            if (payNoData === '') {
              str += '&nbsp;'
            } else {
              str += `<a class="link" onclick="seetleAppTab1.openPopup('PaymentRegPop', 'PaymentDetailPop', ${idx}, ${payNoData}, ${payStsCdData})">` + payNoData + '</a>'
            }

            payNoData = ''
            payStsCdData = ''
          }
        }

        return str
      }
    },
    width: 100
  },
  {
    name: 'payStsCd',
    fieldName: 'payStsCd',
    type: 'text',
    header: { text: 'Pay Status' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const data = cell.value
        const idx = cell.index.dataRow

        let str = ''

        if (data !== undefined && data !== null && data !== '') {
          for (let i = 0; i < data.split('|').length; i++) {
            let payStsCdData = data.split('|')[i]

            if (str !== '') {
              str += '<br />'
            }

            if (payStsCdData === '') {
              str += '&nbsp;'
            } else {
              if (payStsCdData === '01') {
                str += app.$t('msg.CSBK140.001') // Request
              } else if (payStsCdData === '02') {
                str += app.$t('msg.CSBK140.002') // Accept
              } else if (payStsCdData === '03') {
                str += app.$t('msg.CSBK100.024') // Cancel
              } else if (payStsCdData === '04') {
                str += `<a class="link" onclick="seetleAppTab1.openPayRejectCont(event, ${idx}, ${i})">` + app.$t('msg.CSBK140.003') + '</a>' // Reject
              } else if (payStsCdData === '05') {
                str += `<a class="link" onclick="seetleAppTab1.openPayPendingCont(event, ${idx}, ${i})">` + 'Pending</a>' // pending
              }
            }
          }
        }

        return str
      }
    },
    width: 100
  },
  {
    name: 'evdcmtrUpldYn',
    fieldName: 'evdcmtrUpldYn',
    type: 'text',
    header: { text: 'Pay Upload' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const data = cell.value
        const idx = cell.index.dataRow

        let str = ''

        if (data !== undefined && data !== null && data !== '') {
          for (let i = 0; i < data.split('|').length; i++) {
            let evdcmtrUpldYn = data.split('|')[i]

            if (str !== '') {
              str += '<br />'
            }

            if (evdcmtrUpldYn === '') {
              str += '&nbsp;'
            } else {
              str += evdcmtrUpldYn
            }
          }
        }

        return str
      }
    },
    width: 70
  },
  {
    name: 'remark',
    fieldName: 'remark',
    type: 'text',
    header: { text: 'Remark' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const data = cell.value
        const idx = cell.index.dataRow
        // const doStCd = grid.getValue(idx, 'doStCd')
        const doStCd = provider.getValue(idx, 'doStCd')
        // const eiCatCd = grid.getValue(idx, 'eiCatCd')
        const eiCatCd = provider.getValue(idx, 'eiCatCd')
        // const blStCd = grid.getValue(idx, 'blStCd')
        const blStCd = provider.getValue(idx, 'blStCd')

        // const elYn = grid.getValue(idx, 'elYn')
        const elYn = provider.getValue(idx, 'elYn')
        // const cntrStsCd = grid.getValue(idx, 'cntrStsCd')
        const cntrStsCd = provider.getValue(idx, 'cntrStsCd')
        // const cntrCfsYn = grid.getValue(idx, 'cntrCfsYn')
        const cntrCfsYn = provider.getValue(idx, 'cntrCfsYn')
        const certiYn = provider.getValue(idx, 'certiYn')

        let str = ''
        if (eiCatCd === 'I') {
          if (doStCd === '01' || doStCd === '07' || doStCd === '10') {
            str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})">` + app.$t('msg.SETT010T010.102') + '</a>'
          } else if (doStCd === '02' || doStCd === '03' || doStCd === '04' || doStCd === '05' || doStCd === '06' || doStCd === '14') {
            str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})">` + app.$t('msg.ONIM070P020.009') + '</a>' // 불가사유
          } else if (doStCd === '08') {
            str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})">` + app.$t('msg.ONIM070P020.044') + '</a>' // 거절사유
          } else if (doStCd === '09') {
            str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})">Pending ` + app.$t('msg.CMBA380.009') + '</a>' // Pending 사유
          } else if (doStCd === '11' || doStCd === '12' || doStCd === '13') {
            str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})">` + app.$t('msg.MYIN040G010.029') + app.$t('btn.CMBTK048') + '</a>' // 출력불가
          }
        } else { // 수출
          if (blStCd === '11') {
            if (certiYn === 'Y' && seetleAppTab1.memberDetail.userCtrCd === 'KR') {
              str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})">` + app.$t('msg.CSBL220_STS.011') + '</a><br>(CERTI 발행)' // 발급/픽업정보 . O.B/L 재발행
            }
          } else if (blStCd === '01') {
            if (certiYn === 'Y') {
              str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})">` + app.$t('msg.SETT010T010.102') + '</a><br>(CERTI 발행)' // 발급/픽업정보 . O.B/L 발행
            }
          } else if (blStCd === '02') {
            str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})">` + app.$t('msg.SETT010T010.102') + '</a>' // 발급/픽업정보. Surrender 발행
          } else if (blStCd === '03') {
            str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})">` + app.$t('msg.SETT010T010.103') + '</a>' //상세확인 . S/Waybill 발행
          } else if (blStCd === '04') {
            str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})">` + app.$t('msg.SETT010T010.103') + '</a>' //거절사유 . S/Waybill 발행중
          } else if (blStCd === '06') {
            str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})">` + app.$t('msg.SETT010T010.103') + '</a>' //O.B/L 발행중
          } else if (blStCd === '07') {
            str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})"></a>` //Surrender 처리중
          } else if (blStCd === '14') {
            str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})"></a>` //S/Waybill 승인
          } else if (blStCd === '16') {
            str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})"></a>` //O.B/L 승인
          } else if (blStCd === '17') {
            str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})"></a>` //Surrender 승인
          } else if (blStCd === '42') {
            str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})"></a>` //Surrender 취소
          } else if (blStCd === '46') {
            str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})"></a>` //S/Waybill 취소
          } else if (blStCd === '88') {
            str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})">` + app.$t('msg.ONIM070P020.044') + '</a>' //거절사유 .발행거절
          } else if (blStCd === '89') {
            str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})">Pending ` + app.$t('msg.CMBA380.009') + '</a>'
          } else if (blStCd === '99') {
            // 쿼리 적용 기다림.
            if (this.memberDetail.userCtrCd !== 'JP' && cntrStsCd === 'N' && elYn === 'N') {
              str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})">${app.$t('msg.ONIM070P020.009')}</a>` //불가사유 . 신청불가
            } else if (this.memberDetail.userCtrCd !== 'JP' && elYn === 'N') {
              str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})">${app.$t('msg.ONIM070P020.009')}</a>` //불가사유 . 신청불가
            } else if (this.memberDetail.userCtrCd !== 'JP' && cntrStsCd === 'N' && cntrCfsYn === 'N') {
              str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})">${app.$t('msg.ONIM070P020.009')}</a>` //불가사유 . 신청불가
            } else {
              // str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})">상세확인</a>` // 신청가능
            }
          }
        }
        return str
      }
    },
    width: 150
  },
  {
    name: 'blStText',
    fieldName: 'blStText',
    type: 'text',
    header: { text: 'Status' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        let data = cell.value ? cell.value : ''
        const idx = cell.index.dataRow
        // const blStCd = grid.getValue(idx, 'blStCd')
        const blStCd = provider.getValue(idx, 'blStCd')
        // const eiCatCd = grid.getValue(idx, 'eiCatCd')
        const eiCatCd = provider.getValue(idx, 'eiCatCd')
        const rejectUno = provider.getValue(idx, 'rejectUno')

        let str = ''

        if (data !== '' && (data === 'Pending' || data === '발행거절' || data === 'Rejected')) {
          if (seetleAppTab1.memberDetail.userCtrCd === 'KR') {
            data += '<br>↑클릭'
          } else {
            data += '<br>↑Click'
          }
        }

        // if ((blStCd === '01' || blStCd === '11') && rejectUno !== '' && seetleAppTab1.memberDetail.userCtrCd === 'ID') {
        //     data = 'Rejected<br>↑Click'
        // }

        if (['KR', 'JP', 'ID'].indexOf(seetleAppTab1.memberDetail.userCtrCd) >= 0) {
            if (eiCatCd === 'I') {
              str = `<a href="#" onclick="seetleAppTab1.openMsgReason(event, ${idx})"><span class="label gray md minwid60">${data}</span></a>`
            } else {
              str = `<a href="#" onclick="seetleAppTab1.openMsgReason(event, ${idx})"><span class="label gray md minwid60">${data}</span></a>`
            }
        } else {
          if (data === 'Application Available' || data === '신청가능') {
            str = `<a><span class="label gray md minwid60">${data}</span></a>`
          } else {
            if (eiCatCd === 'I') {
              str = `<a href="#" onclick="seetleAppTab1.openMsgReason(event, ${idx})"><span class="label gray md minwid60">${data}</span></a>`
            } else {
              str = `<a href="#" onclick="seetleAppTab1.openMsgReason(event, ${idx})"><span class="label gray md minwid60">${data}</span></a>`
            }
          }
        }

        return str
      }
    },
    width: 150
  },
  { name: 'blStCd', fieldName: 'blStCd', type: 'text', header: { text: 'blStCd' }, editable: false },

  {
    name: 'doStCd',
    fieldName: 'doStCd',
    type: 'text',
    header: { text: '상태2' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const data = cell.value ? cell.value : ''
        const idx = cell.index.dataRow
        let str = ''
        if (data !== '-99') {
          if (data === '01') {
            str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})"><span class="label blue md minwid60">${app.$t('msg.SETT010T010.136')}</span></a>`
          } else if (data === '02' || data === '03' || data === '04') {
            str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})"><span class="label gray md minwid60">${app.$t('msg.CSBL220_STS.099')}</span></a>`
          } else if (data === '05') {
            str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})"><span class="label gray md minwid60">${app.$t('msg.CSBL200.057')}</span></a>`
          } else if (data === '06') {
            str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})"><span class="label gray md minwid60">${app.$t('msg.CSBL220_STS.088')}</span></a>`
          } else if (data === '07') {
            str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})"><span class="label red md minwid60">Pending</span></a>`
          } else if (data === '08' || data === '09' || data === '10' || data === '11') {
            str = `<a onclick="seetleAppTab1.openMsgReason(event, ${idx})"><span class="label green md minwid60">${app.$t('msg.SETT010T010.104')}</span></a>`
          }
        }
        return str
      }
    },
    width: 100
  },
  {
    name: 'loiEtcRmk',
    fieldName: 'loiEtcRmk',
    type: 'text',
    header: { text: '사유확인' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const data = cell.value ? cell.value : ''
        let str = ''
        return data
      }
    }
  },
  {
    name: 'loiReqCatCd',
    fieldName: 'loiReqCatCd',
    type: 'text',
    header: { text: 'loiReqCatCd' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const data = cell.value ? cell.value : ''
        let str = ''
        return data
      }
    }
  },
  { name: 'eiCatCd', fieldName: 'eiCatCd', type: 'text', header: { text: 'eiCatCd' }, editable: false },
  {
 name: 'obrdDt',
    fieldName: 'obrdDt',
    type: 'text',
    header: { text: 'On Board Date' },
    editable: false,
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        const data = cell.value ? cell.value : ''

        var y = data.substring(0, 4)
        var m = data.substring(4, 6)
        var d = data.substring(6, 8)

        return y + '.' + m + '.' + d
      }
    },
    width: 90
  },
  // 쿼리 적용 기다림.
  { name: 'elYn', fieldName: 'elYn', type: 'text', header: { text: 'elYn' }, editable: false },
  { name: 'cntrStsCd', fieldName: 'cntrStsCd', type: 'text', header: { text: 'cntrStsCd' }, editable: false },
  { name: 'cntrCfsYn', fieldName: 'cntrCfsYn', type: 'text', header: { text: 'cntrCfsYn' }, editable: false },
  { name: 'frtPncCd', fieldName: 'frtPncCd', type: 'text', header: { text: 'frtPncCd' }, editable: false },
  { name: 'fwdrCstNo', fieldName: 'fwdrCstNo', type: 'text', header: { text: 'fwdrCstNo' }, editable: false },
  { name: 'isFwdrYn', fieldName: 'isFwdrYn', type: 'text', header: { text: 'isFwdrYn' }, editable: false },
  { name: 'frtPncCdDo', fieldName: 'frtPncCdDo', type: 'text', header: { text: 'frtPncCdDo' }, editable: false },
  { name: 'authImBl', fieldName: 'authImBl', type: 'text', header: { text: 'frtPncCdDo' }, editable: false },
  { name: 'authImInv', fieldName: 'authImInv', type: 'text', header: { text: 'frtPncCdDo' }, editable: false },
  { name: 'authImDo', fieldName: 'authImDo', type: 'text', header: { text: 'frtPncCdDo' }, editable: false }
]

const gridLayout = [
  'bkgNo',
  'blNo',
  'actShprCstEnm',
  'polPortNm',
  'podPortNm',
  'voyNo',
  'vslNm',
  'depVslNm',
  'arvVslNm',
  'obrdDt',
  'cntrCnt',
  'frtCd',
  'occrCurCd',
  'occrAmt',
  // 'exRate',
  'invoiceBtn',
  'issStstus',
  // 'blStatus',
  'blStText',
  'payNo',
  'payStsCd',
  'evdcmtrUpldYn'
  // 'remark'
  // 'frtPncCd'
]

export default {
  name: 'CalcIssueList',
  components: {
    CalcIssue,
    EBreadcrumbs,
    CalcIssueList,
    EDateRangePickerForSettle,
    FreightBillPop,
    InvoicePublishPop,
    demInvoicePop,
    PaymentRegPop,
    BLModifyNotiPop: () => import('@/pages/trans/popup/BLModifyNotiPop'),
    CalcIssueRatePop: () => import('@/pages/settle/popup/CalcIssueRatePop'),
    BlCopyPop: () => import('@/pages/pdf/popup/BlCopyPop'),
    CalcIssueBlMsgPop: () => import('@/pages/settle/popup/CalcIssueBlMsgPop'),
    CalcIssueDoMsgPop: () => import('@/pages/settle/popup/CalcIssueDoMsgPop'),
    CalcIssueFreightPop: () => import('@/pages/settle/popup/CalcIssueFreightPop'),
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    BLPaymentPop: () => import('@/pages/trans/popup/BLPaymentPop'),
    DOPaymentPop: () => import('@/pages/trans/popup/DOPaymentPop'),
    CommonUnsolved: () => import('@/pages/trans/CommonUnsolved'),
    EDateRangePicker: () => import('@/components/common/EDateRangePicker'),
    CombineInvoicePop: () => import('@/pages/pdf/popup/CombineInvoicePop'), /* combine Invoice */
    KmtcCouponPop: () => import('@/pages/schedule/popup/KmtcCouponPop'), /* KmtcCouponPop */
    CalcIssueReceiptPrintPop: () => import('@/pages/pdf/popup/CalcIssueReceiptPrintPop'), /* Receipt 팝업 */
    CalcIssueFareRegisReqPop: () => import('@/pages/trans/popup/CalcIssueFareRegisReqPop') // 운임 등록 요청 팝업
  },
  props: {
    paramData: {
      type: Object,
      default () {
        return {
          tab: '',
          keyword: '',
          dtKnd: '',
          eiCateCd: ''
        }
      }
    }
  },
  data: function () {
    return {
      isHidden: true,
      isShowPop: false,
      customComponent: null,
      detail: {},
      isFilter: false,
      custCompInvoice: null,
      isInvoiceShowPop: false,
      invoiceParam: {},
      invoiceData: [],
      couponList: [],
      isCheckDmd: false,
      params: {
        fromDt: '',
        toDt: '',
        dtKnd: 'BL',
        eiCatCd: 'O',
        blNo: '',
        bkgNo: '',
        shipCd: 'M',
        asProfileYn: 'N',
        asChkDmd: 'N',
        invNo: ''
      },
      beforeParams: {},
      rstList: [],
      rstFilter: [],
      blErrList: [],
      blStatusList: [],
      searchedList: [],
      total: 0,
      templateStr: '',
      isBdialogVisible: false,
      bottomDialogFlag: false,
      htmlData: '',
      sumMap: {
        fuAmt: 0,
        fuTot: 0,
        fkAmt: 0,
        fkTot: 0,
        okAmt: 0,
        okTax: 0,
        okTot: 0,
        tuTot: 0,
        tkTot: 0
      },
      userDmdInfo: {},
      userDmdCatCd: 'R', //화주구분 : 월말화주(M), 청구화주(C), 영수화주(R)
      curDmdCatCd: 'R',
      range: {
        sdate: '',
        edate: ''
      },

      //그리드 변수
      isPaging1: true,
      currentPage: 0,
      totalPage: 0,
      mUserName: '',
      mNumber: '',
      freightBillInfo: [],
      popBtnType: '',
      termType: '02',
      isShowTip1: false,
      isShowTip2: false,
      gridLayout: [],

      popupParams: [],
      couponPopupParams: [],
      popupComponent: null,
      couponComponent: null,
      popupCallback: null,
      popCallback: null,
      tempData: [],
      commonCodes: {},
      isReqView: false,
      parentInfo: {
        blNo: '',
        linkPayNo: '',
        linkYn: 'N',
        payStsCd: ''
      },
      invalidStatusDetail: {},
      receiptComponent: null,
      infoData: {},
      popupReceiptParams: {
        CalcIssueReceiptPrintPop: {
          testUsrNo: '',
          testCtrCd: '',
          testAgtCd: '',
          items: [
            {
              rcvRno: '',
              prtRvstNo: '',
              cstNo: '',
              cstNm: ''
            }
          ]
        }
      },
      // 날짜 체크 변수
      checkFrom: '',
      checkTo: '',
      popupTp2Component: null,
      popupTp2Params: {},
      popupTp2Callback: '',
      auxInvoiceData: [],
      ctrCdList: [],
      selCtr: '',
      selPo: 'POD',
      relYn: true, //process.env.VUE_APP_MODE !== 'PRD' // JamSin

      blNoStrings: '',
      blStsCd: true
    }
  },
  computed: {
    ...rootComputed,
    isInvoicePublishPop () {
      return this.$store.state.settle.isInvoicePublishPop
    },
    selectedProfile () {
      return this.$store.getters['auth/selectedProfile']
    },
    calcEiCatCd () {
      return this.$store.getters['auth/getCalcEiCatCd']
    }
  },
  watch: {
    'params.eiCatCd' (nv, ov) {
      this.gridInit()
      this.changeEdIm()
      if (this.params.eiCatCd === 'I') {
          gridView.columnByName('remark').visible = false
          gridView.columnByName('bkgNo').visible = false

          if (this.memberDetail.userCtrCd !== 'JP') {
            gridView.columnByName('vslNm').visible = true
            gridView.columnByName('depVslNm').visible = false
            gridView.columnByName('arvVslNm').visible = false
          } else {
            gridView.columnByName('vslNm').visible = false
            gridView.columnByName('depVslNm').visible = true
            gridView.columnByName('arvVslNm').visible = true
          }

          if (['KR', 'JP', 'TH', 'VN', 'MY', 'PH'].indexOf(this.memberDetail.userCtrCd) < 0) {
            gridView.columnByName('payNo').visible = true
            gridView.columnByName('payStsCd').visible = true
            gridView.columnByName('evdcmtrUpldYn').visible = true
          }
          // 202220405 심화테스트 검색 조건 변경
          this.params.dtKnd = 'BL'
      } else {
          gridView.columnByName('bkgNo').visible = true
          gridView.columnByName('remark').visible = false
          gridView.columnByName('vslNm').visible = true
          gridView.columnByName('depVslNm').visible = false
          gridView.columnByName('arvVslNm').visible = false
          gridView.columnByName('payNo').visible = false
          gridView.columnByName('payStsCd').visible = false
          gridView.columnByName('evdcmtrUpldYn').visible = false

          // 202220405 심화테스트 검색 조건 변경
          this.params.dtKnd = 'BL'
          this.params.asProfileYn = 'N' // All Shipments
      }
    },
    '$store.state.settle.searchParams' () {
      this.setVuexSearchParams()
    },
    'paramData.keyword' (nv, ov) {
      this.requestView()
    },
    calcEiCatCd () {
      this.params.eiCatCd = this.calcEiCatCd
    }
  },
  created () {
    console.log('App', app.$t('tit.SETTLE.TAB01.GRID_HEADER.001'))
    window.functionOutsideVue(this)
    window.seetleAppTab1 = this
    this.userDmdCatCd = this.$store.state.settle.userDmdCatCd

    // 프로필에서 설정한 수출/수입 값으로 세팅
    this.initEiCatCd()

    this.changeEdIm()
    this.$store.commit('settle/updateIsInvoicePublishPop', true)

    // 프로필에서 설정한 수출/수입 값으로 세팅
    this.initCommCodes()

    // 퀵메뉴 > d/o 발행에서 넘어온 경우 값 세팅
    const tempQuickMain = sessionStorage.getItem('quickMain')
    let quickMain = tempQuickMain === null || tempQuickMain === undefined ? undefined : JSON.parse(tempQuickMain)

    // quickMain.from 체크시 콘솔 오류가 발생하여 null, undefined 체크 추가함
    if (quickMain !== null && quickMain !== undefined) {
      if (quickMain.from === 'quickMainDO') {
        this.params.dtKnd = 'BL'
        this.params.blNo = quickMain.blNo.toUpperCase()
        this.params.eiCatCd = 'I'

        this.searchData()
      }
    }
  },
  mounted: function () {
    this.setDateRange()

    window.vmApp = this
    window.vmAppFunc = this

    //엑셀 다운로드용 JSZip 셋팅
    window.JSZip = window.JSZip || JSZip

    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')

    // 그리드 필드 생성
    provider.setFields(fields)

    // columns 명
    gridView.setColumns(columns)
    gridView.setColumnLayout(gridLayout)
    gridView.setDataSource(provider)

    // 그리고 헤더 높이 조정
    gridView.header.heights = [40]
    gridView.displayOptions.rowHeight = 34
    gridView.displayOptions.fitStyle = 'evenFill'
    gridView.displayOptions.selectionStyle = 'block'

    gridView.setStateBar({
      visible: false
    })
    gridView.setRowIndicator({
      visible: false
    })
    gridView.setCheckBar({
     visible: true,
     exclusive: false,
     mergeRule: "values['blNo']"
    })

    gridView.columnByName('occrAmt').sortable = false
    gridView.columnByName('exRate').sortable = false
    gridView.columnByName('blNo').mergeRule = { criteria: 'value' }
    gridView.columnByName('bkgNo').mergeRule = { criteria: 'value' }
    gridView.columnByName('actShprCstEnm').mergeRule = { criteria: "values['blNo'] + value" }
    gridView.columnByName('polPortNm').mergeRule = { criteria: "values['blNo'] + values['actShprCstEnm'] + value" }
    gridView.columnByName('podPortNm').mergeRule = { criteria: "values['blNo'] + values['actShprCstEnm'] + values['polPortNm'] + value" }
    gridView.columnByName('voyNo').mergeRule = { criteria: "values['blNo'] + values['actShprCstEnm'] + values['polPortNm'] + values['podPortNm'] + value" }
    gridView.columnByName('vslNm').mergeRule = { criteria: "values['blNo'] + values['actShprCstEnm'] + values['polPortNm'] + values['podPortNm'] + values['voyNo'] + value" }
    gridView.columnByName('depVslNm').mergeRule = { criteria: "values['blNo'] + values['actShprCstEnm'] + values['polPortNm'] + values['podPortNm'] + values['voyNo'] + value" }
    gridView.columnByName('arvVslNm').mergeRule = { criteria: "values['blNo'] + values['actShprCstEnm'] + values['polPortNm'] + values['podPortNm'] + values['arvVoyNo'] + value" }
    gridView.columnByName('obrdDt').mergeRule = { criteria: "values['blNo'] + value" }
    gridView.columnByName('cntrCnt').mergeRule = { criteria: "values['blNo'] + value" }
    gridView.columnByName('frtCd').mergeRule = { criteria: "values['blNo'] + value" }
    gridView.columnByName('invoiceBtn').mergeRule = { criteria: "values['blNo'] + value" }
    gridView.columnByName('issStstus').mergeRule = { criteria: "values['blNo'] + value" }
    gridView.columnByName('blStatus').mergeRule = { criteria: "values['blNo'] + value" }
    gridView.columnByName('remark').mergeRule = { criteria: "values['blNo'] + value" }
    gridView.columnByName('blStText').mergeRule = { criteria: "values['blNo'] + value" }
    gridView.columnByName('blStCd').mergeRule = { criteria: "values['blNo'] + value" }

    gridView.columnByName('occrAmt').mergeRule = { criteria: "values['blNo'] + value + values['occrCurCd']" }
    gridView.columnByName('exRate').mergeRule = { criteria: "values['blNo'] + value + values['occrCurCd']" }

    gridView.columnByName('payNo').mergeRule = { criteria: "values['blNo'] + value" }
    gridView.columnByName('payStsCd').mergeRule = { criteria: "values['blNo'] + value" }
    gridView.columnByName('evdcmtrUpldYn').mergeRule = { criteria: "values['blNo'] + value" }

    this.gridInit()
    this.requestView()

    if (this.params.eiCatCd === 'I') {
      gridView.columnByName('remark').visible = false
    }

    if (this.memberDetail.userCtrCd !== 'KR') {
      gridView.columnByName('issStstus').visible = false
      if (['JP', 'TH', 'VN', 'MY', 'PH'].indexOf(this.memberDetail.userCtrCd) >= 0) {
        gridView.columnByName('payNo').visible = false
        gridView.columnByName('payStsCd').visible = false
        gridView.columnByName('evdcmtrUpldYn').visible = false
      }
    } else {
      gridView.columnByName('payNo').visible = false
      gridView.columnByName('payStsCd').visible = false
      gridView.columnByName('evdcmtrUpldYn').visible = false
    }
  },
  methods: {
    initEiCatCd () {
      if (this.calcEiCatCd !== '') {
        this.params.eiCatCd = this.calcEiCatCd
      } else {
        if (this.selectedProfile.eiCatCd === 'A') {
          // 프로필 값이 수출/수입이면 '수출'
          this.params.eiCatCd = 'O'
        } else {
          // 프로필에서 설정한 수출/수입 값으로 세팅
          this.params.eiCatCd = this.selectedProfile.eiCatCd
        }
      }
    },
    openFreetimeTab () {
      console.log('openFreetimeTab')

      let chkValue = this.setFreetimeReqData()
      if (chkValue) {
        this.$emit('moveFreetime')
      }
    },
    setFreetimeReqData () {
      let canMoveFreetime = true

      // Freetime 요청
      const checkedItems = gridView.getCheckedRows(true)
      const checkedRowCnt = checkedItems.length
      if (checkedItems.length === 0) {
        // this.openAlert('Freetime 요청 할 한개의 B/L을 선택하세요') // Freetime 요청 할 한개의 B/L을 선택하세요
        this.openAlert(this.$t('msg.SETT010T010.196')) // Freetime 요청 할 한개의 B/L을 선택하세요
        canMoveFreetime = false
        return
      }

      let blList = []

      checkedItems.forEach(idx => {
        const tmp = provider.getJsonRows(idx, idx)
        if (tmp.length > 0) {
          const vo = tmp[0]
          const isBlExist = blList.findIndex(v => v.blNo === vo.blNo)
          if (isBlExist === -1) {
            blList.push(vo)
          }
        }
      })
      console.log('checkedItems blList >>> ', blList)

      if (blList.length > 1) {
        this.openAlert('Freetime 요청은 한개의 B/L만 가능합니다.') // Freetime 요청은 한개의 B/L만 가능합니다.
        canMoveFreetime = false
        return
      }
      // this.detail = blList[0]

      // GA dataLayer 추가
      window.dataLayer.push({
        event: 'set_popup_info',
        popup_booking_no: blList[0].bkgNo,
        popup_bl_no: blList[0].blNo
      })

      let params = {
        eiCatCd: this.params.eiCatCd,
        dtKnd: 'BL',
        num: blList[0].blNo,
        blNo: '',
        cntrNo: ''
      }

      this.$store.commit('settle/updateFreetimeParams', params)

      return canMoveFreetime
    },
    async initCommCodes () {
      const arrCdId = ['01043', 'CS068', '01122', '01086', '01113'] // 01122(C/A 구분 코드), 01076(C/A 사유 코드)
      await Promise.all([
        commons.getCommonsCodesCommon(arrCdId).then(res => {
          this.commonCodes = res
        }),
        commons.getCommonsCodesCountry().then(res => {
          this.ctrCdList = res
        })
      ])
    },
    setDateRange () {
      this.range = {
        sdate: this.getWeekDt(-7),
        edate: this.getWeekDt(7)
      }
      this.params.fromDt = this.range.sdate
      this.params.toDt = this.range.edate
    },

    gridInit () {
      this.rstList = []
      provider.setRows(this.rstList)
      this.total = this.rstList.length
      this.currentPage = gridView.getPage()
      this.totalPage = gridView.getPageCount()
    },

    getDateStr (myDate) {
      let year = myDate.getFullYear()
      let month = (myDate.getMonth() + 1)
      let day = myDate.getDate()

      month = (month < 10) ? '0' + String(month) : month
      day = (day < 10) ? '0' + String(day) : day

      return year.toString() + month.toString() + day.toString()
    },
    getWeekDt (n) {
      let d = new Date()
      let dayOfMonth = d.getDate()
      d.setDate(dayOfMonth + n)
      return this.getDateStr(d)
    },
    requestView () {
      console.log('---------requestView---------')
      this.isReqView = true
      if (this.paramData.tab === '1') {
        console.log('this.paramData >>', this.paramData)
        this.params.dtKnd = this.paramData.dtKnd
        this.params.blNo = this.paramData.keyword
        this.params.eiCatCd = this.paramData.eiCatCd
        this.params.asProfileYn = this.paramData.asProfileYn
        this.searchData()
      }
    },
    changeEdIm () {
      if (this.params.eiCatCd === 'I') {
        this.curDmdCatCd = this.$store.state.settle.imDmdCatCd
        this.params.asProfileYn = 'N'
        if (!this.isReqView) {
          this.params.dtKnd = 'AD'
        }
      } else {
        this.curDmdCatCd = this.$store.state.settle.etDmdCatCd

        // 20220405 심화테스트 - 수출 기본값 변경
        this.params.asProfileYn = 'N'
        this.params.dtKnd = 'BL'
      }
    },
    goDetailPageAtGrid (e, gridIndex) {
      e.preventDefault()

      let chkGoDetail = true
      const THIS = this
      const params = {}

      if (gridIndex > -1) {
        const tmp = provider.getJsonRows(gridIndex, gridIndex)
        if (tmp.length > 0) {
          const vo = tmp[0]

          if (this.params.eiCatCd === 'O') {
            const { bkgNo, srRno, blNo, bkgShprCstNo } = vo

            params.blNo = blNo
            params.bkgNo = bkgNo
            params.bkgShprCstNo = bkgShprCstNo

            // #1. 스위치 비엘 권한체크
            CalcIssue.checkInvoiceBlAuth(params)
             .then(response => {
                if (response.data === 'N') {
                  chkGoDetail = false
                } else {
                  chkGoDetail = true
                }
              })
            .catch(err => {
              console.log(err)
            })
          }
        }
      }

      const popParams = {
        page: 'BL_EDIT',
        params: params
      }

      // #2. 스위치 비엘 권한체크
      if (chkGoDetail) {
        params.chkGoDetail = chkGoDetail

        // bl 수정 동의 팝업 콜백
        this.popCallback = (p) => {
          THIS.closePopup()
          THIS.goBlDetail(p.page, p.params)
        }

        this.parentInfo = popParams
        this.openPopup('BLModifyNotiPop', 'goBlModifyNoti')
      } else {
        this.openAlert(this.$t('art.CMATK232')) // 권한이 없습니다.
      }
    },
    getInvoice (e, gridIndex) {
      e.preventDefault()
      // this.getInvoiceInfo3(gridIndex)
      this.getInvoiceInfo4(gridIndex)
    },
    setVuexSearchParams () {
      /* TODO
        서브개인화 B/L 미발행, D/O 미발행 링크 클릭시 vuex에 파라미터 정보가 저장되고,
        watch에서 저장되는 action을 감지하여 이 메소드가 호출되도록 하였습니다. 파라미터 세팅해서 검색하도록 구현해주세요.
        this.$store.state.settle.searchParams.*
      */
      console.log('---------setVuexSearchParams---------')
      console.log(this.$store.state.settle.searchParams)
      const pcParams = this.$store.state.settle.searchParams
      // this.termType = ''
      this.params.fromDt = pcParams.fromDt
      this.params.toDt = pcParams.toDt
      this.params.dtKnd = pcParams.dtKnd
      this.params.eiCatCd = pcParams.eiCatCd
      this.params.asProfileYn = 'N'

      this.searchData()
    },
    changeDateRange (s, d) {
      this.params.fromDt = s
      this.params.toDt = d

      this.checkFrom = moment(s).format('YYYY-MM-DD')
      this.checkTo = moment(d).format('YYYY-MM-DD')

      this.checkSearchValidation('fromDtToDt')
    },
    checkSearchValidation (id) {
      const searchParams = this.params
      const frm = document.querySelector('#frm')
      let selector = document.querySelector('#' + id)
      let isOk = true

      if (selector === null || selector === undefined) {
        return true
      }

      // this.$ekmtcCommon.hideErrorTooltip(selector)
      this.localHideErrorTooltip(selector)

      switch (id) {
        case 'fromDtToDt':
          var fromDt = new Date(this.checkFrom)
          var toDt = new Date(this.checkTo)

          if (this.params.dtKnd !== 'BL' && (this.$ekmtcCommon.isEmpty(this.params.fromDt) || this.$ekmtcCommon.isEmpty(this.params.toDt))) {
            this.LocalShowErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          } else if (this.params.dtKnd !== 'BL' && fromDt > toDt) {
            this.LocalShowErrorTooltip(selector, this.$t('msg.ONEX010T010.128'))
            isOk = false
          }
          break
      }
      return isOk
    },
    LocalShowErrorTooltip (elem, message) {
          this.validationDt = true

          let $elem = $(elem)
          let $parent = $elem.parents('td, div').eq(0)
          let $parentTd = $parent.parents('td,div').eq(0)
          let $parentLi = $elem.parents('li').eq(0)

          $elem.addClass('input_error')
          let $sMsgBox = ''
          let $msgBox = ''

          // $parentTd.addClass('position_relative')
          $msgBox = $parent.find('.tooltip_essen_box')

          if ($sMsgBox.length === 0) {
            $msgBox = $('<p class=\'tooltip_essen_box\'><span class=\'tooltip_essen\'></span></p> ')
            $msgBox.appendTo($parentLi)
          }
          $msgBox.find('.tooltip_essen').eq(0).text(message).show()
    },
    localHideErrorTooltip (elem) {
          this.validationDt = false
          const $elem = $(elem)
          const $parent = $elem.parents('td, div').eq(0)
          let $parentLi = $elem.parents('li').eq(0)

          $elem.removeClass('input_error')
          $parentLi.find('.tooltip_essen').hide()
    },
    msgBoxTest () {
      this.$bvModal.hide()
    },
    async searchData () {
      if (this.$store.state.settle.isSettleSearchData) {
        this.params = JSON.parse(JSON.stringify(this.beforeParams))
      }
      this.$store.commit('settle/updateIsSettleSearchData', false)

      let msnYn = 'N'

      if (this.params.dtKnd === 'BL' && this.params.eiCatCd === 'I') {
        const blNo = this.params.blNo

        await importSvc.getMsnYn(blNo).then(res => {
          msnYn = res.data
        })

        if (msnYn === 'Y') {
          this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.DOIssue.028') }) // 선적지로부터 B/L DATA가 접수 후 조회 가능합니다.
          return
        }
      }

      if (this.optionChk()) {
        this.rstList = []
        this.rstFilter = []

        store.commit('updateWorkingEiCatCd', this.params.eiCatCd)

        console.log(JSON.stringify(this.params))
        await CalcIssue.getInvoiceList(this.params)
          .then(response => {
            this.isReqView = false
            this.beforeParams = JSON.parse(JSON.stringify(this.params))
            for (let row of response.data.rstList) {
              row.blStText = this.parseBlStsCd(row)
            }
            let resp = []
            if (this.params.dtKnd === 'BL' && this.params.eiCatCd === 'I') {
              resp = response.data.rstList.map(vo => {
                //vo.apoExchRt = 1210.30
                const eiCatCd = this.params.eiCatCd
                if (!this.isPermitDo(vo.actShprCstEnm, 'enm')) {
                  vo.occrAmt = 0
                  vo.exRate = 0
                  //vo.actShprCstEnm = '***'
                }
                return { ...vo, eiCatCd: eiCatCd }
              })
            } else {
              resp = response.data.rstList.map(vo => {
                const eiCatCd = this.params.eiCatCd
                return { ...vo, eiCatCd: eiCatCd }
              })
            }
            this.blErrList = response.data.blErrCodeList
            // this.blStatusList = response.data.blStatusList
            this.searchedList = this.$_.cloneDeep(resp)

            console.log(JSON.stringify(response.data.rstList[0]))

            this.total = this.getTotalCnt(resp)
            this.rstList = this.$_.sortBy(resp, ['blNo'])
            this.rstFilter = this.$_.sortBy(resp, ['blNo'])

            // BL 상태 컬럼 추가
            // for (var rst of this.rstFilter) {
            //   for (var blStatus of this.blStatusList) {
            //     if (rst.blNo === blStatus.blNo) {
            //       rst.blStsCd = blStatus.blStsCd
            //       // rst.blStsCd = 'A5'
            //     }
            //   }
            // }

            provider.setRows(this.rstList)
            gridView.setPage(-1)

            if (this.params.eiCatCd === 'I') {
              gridView.columnByName('remark').visible = true

              if (['KR', 'JP', 'TH', 'VN', 'MY', 'PH'].indexOf(this.memberDetail.userCtrCd) < 0) {
                gridView.columnByName('payNo').visible = true
                gridView.columnByName('payStsCd').visible = true
                gridView.columnByName('evdcmtrUpldYn').visible = true
              }
            } else {
              gridView.columnByName('remark').visible = false
              gridView.columnByName('payNo').visible = false
              gridView.columnByName('payStsCd').visible = false
              gridView.columnByName('evdcmtrUpldYn').visible = false
            }

            console.log(this.rstList)
            this.currentPage = gridView.getPage() //현재 페이지 반환
            this.totalPage = gridView.getPageCount() //전체페이지 개수 반환
            gridView.setAllCheck(false)

            if (this.total === 0) {
              this.openAlert(this.$t('msg.SETT010T010.129'))
            }
          })
          .catch(err => {
            this.isReqView = false
            console.log(err)
          })
          .finally(() => {
          })
      }
    },
    getTotalCnt (arr) {
      let blList = []
      for (let obj of arr) {
        if (blList.length === 0) {
          blList.push(obj)
        } else {
          let isExist = false
          for (let bl of blList) {
            if (obj.blNo === bl.blNo) {
              isExist = true
              break
            }
          }
          if (!isExist) {
            blList.push(obj)
          }
        }
      }
      return blList.length
    },

    /**
     * 운임수납 전 BL 검증
     */
    async checkBl () {
      // 사전 검증
      if (this.isGridCheckData() > 1) {
        this.openAlert(this.$t('msg.ADD20220219.001')) //'FREIGHT와 DEM/DET/OFC는 과세구분이 상이하여 개별로 진행바랍니다.'
        return
      }
      let checkedItems = gridView.getCheckedRows(true)
      let info
      let blNoList = []
      let voArr = []
      checkedItems.forEach(inx => {
        const tmp = provider.getJsonRows(inx, inx)
        voArr.push(tmp[0])

        if (tmp.length > 0) {
          const vo = tmp[0]
          if (this.params.dtKnd === 'BL' && this.params.eiCatCd === 'I') {
            if (this.isPermitDo(vo.actShprCstEnm, 'enm')) {
              const tt = blNoList.filter(v => v.blNo === vo.blNo)
              if (tt.length === 0) {
                blNoList.push({
                    blNo: vo.blNo,
                    payCstNo: '',
                    eiCatCd: this.params.eiCatCd
                  })
              }
            }
          } else {
            const tt = blNoList.filter(v => v.blNo === vo.blNo)
            if (tt.length === 0) {
              blNoList.push({
                  blNo: vo.blNo,
                  payCstNo: '',
                  eiCatCd: this.params.eiCatCd
                })
            }
          }
        }
      })
        console.log('-------------------------')
        console.log(voArr[0])
        console.log('-------------------------')
      if (this.params.dtKnd === 'BL' && this.params.eiCatCd === 'I') {
        // if (blNoList.length === 0) {
        //   this.openAlert('권한이 없는 B/L 입니다.')
        //   return
        // }
        if (!this.isPermitDo(voArr, 'arr')) {
          this.openAlert(this.$t('msg.ADD20220219.002')) // '권한이 없는 B/L 입니다.'
          return
        }
      } else {
        if (blNoList.length === 0) {
          this.openAlert(this.$t('msg.ADD20220219.003')) // 'Invoice 대상 B/L 없습니다.'
          return
        }
      }

      console.log('Bl Checked >>', blNoList)
      const param = blNoList
      await CalcIssue.checkBl(param)
        .then(response => {
          console.log(response)
          if (response.data) {
            info = response.data
            if (info.result !== 'N') {
              if (info.result === '01') {
                this.openAlert(this.$t('msg.DOIssue.001') + `(${info.blNo})`) // 정산할 금액이 없습니다.
              } else {
                // this.openAlert(`요청사항이 처리중입니다. (${info.blNo}:${info.result})`)
                this.openAlert(this.$t('msg.ADD20220219.004') + `(${info.blNo})`) //발행 요청을 처리 중에 있습니다.
              }
            } else {
              console.log('>>>> openFreightPop <<<<')
              this.openFreightPop()
            }
          }
        })
    },
    async getInvoiceInfo2 (inx) {
      // invoice info checked
      console.log(inx)
      if (inx > -1) {
        const tmp = provider.getJsonRows(inx, inx)
        if (tmp.length > 0) {
          const vo = tmp[0]
          console.log('vo info > ', vo)
          if (this.$_.trim(vo.invIssNo) !== '') {
            if (vo.frtCd === 'Freight') {
              this.invoiceParam = {
                testCtrCd: '',
                testBkgPlcCd: '',
                testUserId: '',
                items: []
              }
              const invoceInfo = {
                invIssNo: vo.invIssNo,
                to: '',
                remark: '',
                filter: 'BL',
                blNo: vo.blNo,
                esign: ''
              }
              this.invoiceParam.items.push(invoceInfo)
              console.log('PDF >>', this.invoiceParam)
              this.custCompInvoice = 'BlInvoiceFreightPop'
            } else {
              // DEM/DET Invoice Popup open
              switch (vo.demDetCatCd) {
                case '01':
                case '02':
                case '03':
                case '04':
                case '08':
                case '14':
                  break
                default:
                  return
              }
              const param = {
                blNo: vo.blNo,
                demDetCatCd: vo.demDetCatCd,
                eiCatCd: this.params.eiCatCd
              }
              let invInfo = []
              await searchDemurrageDetailFormApi.getInvoice(param)
                .then(async response => {
                  console.log('response ::: ', response)
                  if (response.data.rstList) {
                    invInfo = await response.data.rstList.filter(v => {
                      return v.invIssNo === vo.invIssNo
                    })
                  }
                })
              if (invInfo.length > 0) {
                this.custCompInvoice = 'demInvoicePop'
                this.invoiceParam = {
                  invoice: JSON.parse(JSON.stringify(invInfo[0])),
                  blNo: vo.blNo,
                  lang: this.auth.serviceLang,
                  usrNm: this.selectedProfile.picEnm,
                  ctrCd: this.memberDetail.userCtrCd,
                  demDetCatCd: vo.demDetCatCd,
                  eiCatCd: this.params.eiCatCd
                }
              } else {
                return
              }
            }
            this.isInvoiceShowPop = true
            $('#layerInvoice > .popup_dim').fadeIn()
            $('body,html').css('overflow', 'hidden')
          }
        }
      }
    },
    async getInvoiceInfo3 (idx) {
      console.log('getInvoiceInfo3')
      this.invoiceData = []

      let collectBl = []

      if (idx > -1) {
        const tmp = provider.getJsonRows(idx, idx)
        if (tmp.length > 0) {
          const v = tmp[0]

          if (v.frtCd === 'Freight') {
            const blNo4Pdf = v.blNo
            let invoiceData2 = []
            for (const item of this.rstList) {
              if (item.blNo === blNo4Pdf && item.frtCd === 'Freight') {
                const tt2 = {
                  invIssNo: item.invIssNo,
                  blNo: item.blNo,
                  invRno: item.invRno,
                  index: item.reqCnt // 없으면 0
                }
                const isBlExist = invoiceData2.findIndex(v => v.blNo === item.blNo && v.invIssNo === item.invIssNo)

                if (isBlExist === -1) {
                  if (this.params.dtKnd === 'BL' && this.params.eiCatCd === 'I') {
                    if (v.actShprCstEnm === '**') {
                      if (v.frtPncCdDo === 'P') { // prepaid BL
                          invoiceData2.push(tt2)
                      } else { // collect BL
                          collectBl.push(tt2)
                      }
                    } else {
                      invoiceData2.push(tt2)
                    }
                  } else {
                    invoiceData2.push(tt2)
                  }
                }
              }
            }
            if (invoiceData2.length > 0) {
              this.$refs.invoicePub.openInvoicePop(invoiceData2, this.params.eiCatCd)
            } else {
              if (this.params.dtKnd === 'BL' && this.params.eiCatCd === 'I') {
                this.openAlert(this.$t('msg.ADD20220219.002')) //'권한이 없는 B/L 입니다.'
              } else {
                this.openAlert(this.$t('msg.ADD20220219.005')) // '선택된 B/L이 없습니다.'
              }
            }
          } else {
            // DEM/DET Invoice Popup open
            switch (v.demDetCatCd) {
              case '01':
              case '02':
              case '03':
              case '04':
              case '08':
              case '14':
                break
              default:
                return
            }
            const param = {
              blNo: v.blNo,
              demDetCatCd: v.demDetCatCd,
              eiCatCd: this.params.eiCatCd
            }
            let invInfo = []
            await searchDemurrageDetailFormApi.getInvoice(param)
              .then(async response => {
                console.log('response ::: ', response)
                if (response.data.rstList) {
                  invInfo = await response.data.rstList.filter(vv => {
                    return vv.invIssNo === v.invIssNo
                  })
                }
              })
            if (invInfo.length > 0) {
              this.custCompInvoice = 'demInvoicePop'
              this.invoiceParam = {
                invoice: JSON.parse(JSON.stringify(invInfo[0])),
                blNo: v.blNo,
                lang: this.auth.serviceLang,
                usrNm: this.selectedProfile.picEnm,
                ctrCd: this.memberDetail.userCtrCd,
                demDetCatCd: v.demDetCatCd,
                eiCatCd: this.params.eiCatCd
              }
              this.isInvoiceShowPop = true
              $('#layerInvoice > .popup_dim').fadeIn()
              $('body,html').css('overflow', 'hidden')
            }
          }
        }
      }
    },
    // Invoice 발행 및 출력 버튼 호출
    async openInvoice2 (path, idx) {
      this.invoiceData = []
      this.auxInvoiceData = []
      let rows = []
      this.couponList = []

      if (path === 'btn') {
        rows = gridView.getCheckedRows(true)

        if (rows.length === 0) {
          this.openAlert(this.$t('msg.ADD20220219.009')) //'출력 대상에 추가할 B/L을 선택해주세요.'
          return
        }
      } else {
        rows.push(idx)
      }

      let blList = []
      let collectBl = []

      rows.forEach(idx => {
        const tmp = provider.getJsonRows(idx, idx)
        console.log(tmp)

        if (tmp.length > 0) {
          const vo = tmp[0]

          // Collect BL 일때, INVOICE 출력 가능 경우 조건 추가
          if (this.params.eiCatCd === 'I') {
            const isBlExist = blList.findIndex(v => v.blNo === vo.blNo && v.invIssNo === vo.invIssNo)
            if (isBlExist === -1) {
              if (vo.frtPncCdDo === 'P') { // prepaid BL
                blList.push(vo)
              } else if (vo.frtPncCdDo === 'C') { // collect BL
                if (vo.authImInv === 'A' || this.memberDetail.staffFlag === 'Y') {
                  blList.push(vo)
                } else {
                  collectBl.push(vo)
                }
              }
            }
          } else {
            const isBlExist = blList.findIndex(v => v.blNo === vo.blNo && v.invIssNo === vo.invIssNo)
            if (isBlExist === -1) {
              blList.push(vo)
            }
          }
        }
      })
      blList.forEach(v => {
        const tt = {
          invIssNo: v.invIssNo ? v.invIssNo : '',
          blNo: v.blNo,
          invRno: v.invRno ? v.invRno : '',
          invRno1: v.invRno1 ? v.invRno1 : '',
          index: v.reqCnt ? v.reqCnt : 0, // 없으면 0
          // frtPncCd: v.frtPncCd
          frtPncCd: v.frtPncCdDo
        }
        if (this.memberDetail.userCtrCd !== 'ID') {
          if (v.frtCd === 'Freight') {
            this.invoiceData.push(tt)
          }
        } else {
          this.invoiceData.push(tt)
        }
        const tempAuxBl = {
          invIssNo: v.invIssNo ? v.invIssNo : '',
          blNo: v.blNo,
          invRno: v.invRno ? v.invRno : '',
          index: v.reqCnt ? v.reqCnt : 0, // 없으면 0
          // frtPncCd: v.frtPncCd
          frtPncCd: v.frtPncCdDo
        }

        if (v.podCtrCd === 'ID' || v.polCtrCd === 'ID') {
          this.auxInvoiceData.push(tempAuxBl)
        }

        // GA dataLayer 추가
        window.dataLayer.push({
          event: 'set_popup_info',
          popup_booking_no: v.bkgNo,
          popup_bl_no: v.blNo
        })
      })
      let invoiceData = []
      let tmpInvoiceData = []

      if (this.invoiceData.length > 0) {
        // console.log('대상 : ', this.invoiceData)

        await this.recalculateFreightBillInfo()

        await this.auxInvoiceCreate()

        let chkData = this.checkInvoiceData(this.invoiceData)

        if (chkData) {
          this.invoiceData.forEach(i => {
            const blNo = i.blNo
            tmpInvoiceData.push(blNo)
          })

          await this.couponUseChk()

          if (this.couponList.length > 0) {
            if (await this.$ekmtcCommon.asyncAlertMessage({ message: '사용 가능한 쿠폰이 있습니다 사용 하시겠습니까?', useConfirmBtn: true })) {
              var cnt = 0
              for (var k = 0; k < tmpInvoiceData.length; k++) {
                cnt = 0
                for (var j = 0; j < this.couponList.length; j++) {
                  if (this.couponList[j].blNo === tmpInvoiceData[k]) {
                    cnt = cnt + 1
                  }
                }
                if (cnt === 0) {
                  const tempCouponData = {
                    blNo: tmpInvoiceData[k],
                    cntrTypCd: 'N',
                    dlyCtrCd: '',
                    dlyPlcCd: '',
                    porCtrCd: '',
                    porPlcCd: '',
                    qty20: '',
                    qty40: '',
                    couponUse: '사용 불가'
                  }

                  this.couponList.push(tempCouponData)
                }
              }
              await this.kmtcCouponPopCall()
            }
          }
          tmpInvoiceData = [...new Set(tmpInvoiceData)]

          for (var i = 0; i < tmpInvoiceData.length; i++) {
            invoiceData.push({ blNo: tmpInvoiceData[i], remark: '' })
          }

          console.log('invoiceData >>>>> ', invoiceData)
          this.combineInvoicePopCall(invoiceData, this.params.eiCatCd)
        } else {
          this.openAlert(this.$t('msg.DOIssue.003')) // Invoice를 출력할 B/L이 없습니다.
        }
      } else {
        this.openAlert(this.$t('msg.ADD20220219.002')) //'권한이 없는 B/L 입니다.'
      }
    },
    onClickCellExRate (e, inx = 0) {
      e.preventDefault()

      if (inx >= -1) {
        const tmp = provider.getJsonRows(inx, inx)
        if (tmp.length > 0) {
          const vo = tmp[0]

          console.log('## data >>', vo)
          if (vo.frtCd === 'Freight') {
            if (vo.occrCurCd === 'USD') {
              const apoExchRt = vo.apoExchRt
              // exRate.
              // 입항일 하루전 환율 적용, 입항일 하루 이전에 발행한 Invoice 건만 비교하여 환차 비교
              if (this.$_.trim(vo.invIssNo) !== '' && vo.exRate !== apoExchRt) {
                // 팝업을 오픈하지 않는다
                this.detail = {
                  ...vo,
                  eiCatCd: this.params.eiCatCd
                }
                console.log('object data >>', this.detail)
                this.openNewPopup('CalcIssueRatePop')
              }
            }
          }
        }
      }
    },
    goBlDetail (page, params) {
      // BL 상세 이동
      if (params.chkGoDetail) {
        this.$router.push({ name: 'bl', params: params }).catch(() => {})
      }
    },
    goFreight (inx) {
      // Freight
      console.log(inx)
      if (inx > -1) {
        const tmp = provider.getJsonRows(inx, inx)
        if (tmp.length > 0) {
          const vo = tmp[0]
          if (this.params.eiCatCd === 'O') {
            const bkgNo = vo.bkgNo
            const srRno = ''
            const blNo = vo.blNo

            console.log('BL Detail >>', vo)
            this.$router.push({ name: 'freight', params: { bkgNo: bkgNo, srRno: srRno, blNo: blNo } }).catch(() => {})
          }
        }
      }
    },
    templateEnter (e) {
      const gridList = this.rstList
      if (this.templateStr === '') {
        this.isFilter = false
        provider.setRows(this.rstList)
        this.total = this.getTotalCnt(this.rstList)
        this.currentPage = gridView.getPage() //현재 페이지 반환
        this.totalPage = gridView.getPageCount()
      } else {
        this.isFilter = true
        this.rstFilter = gridList.filter(vo => vo.blNo.indexOf(this.templateStr) > -1)
        if (this.rstFilter.length > 0) {
          provider.setRows(this.rstFilter)
          gridView.setPage(1)
          this.total = this.getTotalCnt(this.rstFilter)
          this.currentPage = gridView.getPage() //현재 페이지 반환
          this.totalPage = gridView.getPageCount()
        }
      }
    },
    // filter
    changeFilter () {
      const gridList = this.rstList

      let arrFilter

      if (this.templateStr.indexOf(',') !== -1) {
        arrFilter = this.templateStr.split(',')
      } else {
        arrFilter = [this.templateStr]
      }

      if (this.templateStr === '') {
        this.rstFilter = gridList
      } else {
        this.rstFilter = gridList.filter(vo => {
          const blNo = vo.blNo === undefined ? '' : vo.blNo.toLowerCase()

          let txtFilter

          for (let i in arrFilter) {
            txtFilter = arrFilter[i].toLowerCase().replace(/^\s+|\s+$/g, '')
            if (txtFilter === '') {
              continue
            }
            if (blNo.indexOf(txtFilter) !== -1) {
              return true
            }
          }
          return false
        })
      }
      provider.setRows(this.rstFilter)
      gridView.setPage(1)
      this.total = this.getTotalCnt(this.rstFilter)
      this.currentPage = gridView.getPage() //현재 페이지 반환
      this.totalPage = gridView.getPageCount()
      gridView.setTopItem(0) //결과재검색후 스크롤을 무조건 최상단으로 올린다
    },
    async requestPop () {
      // 운임수정 요청
      const checkedItems = gridView.getCheckedRows(true)
      const checkedRowCnt = checkedItems.length
      if (checkedItems.length === 0) {
        this.openAlert(this.$t('msg.ADD20220219.006')) // '운임수정 요청 할 한개의 B/L을 선택하세요')
        return
      }
      let blList = []
      let voArr = []
      checkedItems.forEach(idx => {
        const tmp = provider.getJsonRows(idx, idx)
        voArr.push(tmp)
        if (tmp.length > 0) {
          const vo = tmp[0]
          const isBlExist = blList.findIndex(v => v.blNo === vo.blNo)
          if (isBlExist === -1) {
            blList.push(vo)
          }
        }
      })
      if (blList.length > 1) {
        this.openAlert(this.$t('msg.ADD20220219.007')) // '운임수정 요청은 한개의 B/L만 가능합니다.'
        return
      }
      this.detail = blList[0]

      // GA dataLayer 추가
      window.dataLayer.push({
        event: 'set_popup_info',
        popup_booking_no: blList[0].bkgNo,
        popup_bl_no: blList[0].blNo
      })

      if (this.params.dtKnd === 'BL' && this.params.eiCatCd === 'I' && !this.isPermitDo(voArr, 'arr')) {
        this.openAlert(this.$t('msg.ADD20220219.002')) //'권한이 없는 B/L 입니다.'
        return
      }
      console.log('this.detail >> ', this.detail)
      const param = {
        reqCatCd: '12',
        docRno: this.detail.blNo
      }
      console.log(JSON.stringify(param))
      await CalcIssue.reqChk(param)
        .then(async response => {
          console.log('Bl Check >', response)
          if (response.data) {
            const re = response.data
                          this.customComponent = 'CalcIssueFreightPop'
              this.isShowPop = true
              $('#layerPop > .popup_dim').fadeIn()
              $('body,html').css('overflow', 'hidden')
            // if (re.rstCd === 'Y') {
            //   this.customComponent = 'CalcIssueFreightPop'
            //   this.isShowPop = true
            //   $('#layerPop > .popup_dim').fadeIn()
            //   $('body,html').css('overflow', 'hidden')
            // } else {
            //   if (!this.$_.isEmpty(re.rstMsg)) {
            //     if (this.memberDetail.userCtrCd === 'KR') {
            //       this.openAlert(re.rstMsg)
            //     } else {
            //       this.openAlert('Only KR with POL code can be requested.')
            //     }
            //   }
            // }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    optionChk: function () {
      if (this.params.dtKnd === 'BL') {
        if (this.params.blNo === '') {
            this.openAlert(this.$t('msg.CSCT060G060.025')) // B/L NO를 입력하여 주세요.
            return false
        }
      } else {
        if (this.params.fromDt === '') {
            this.openAlert('Please input start date.')
            return false
        }
        if (this.params.toDt === '') {
            this.openAlert('Please input end date.')
            return false
        }
      }
      return true
    },
    isGridCheckData () {
      const checkedItems = gridView.getCheckedRows(true)
      let frtList = []
      checkedItems.forEach(idx => {
        const tmp = provider.getJsonRows(idx, idx)
        if (tmp.length > 0) {
          const vo = tmp[0]
          const fr = frtList.filter(v => v === vo.frtCd)
          if (fr.length === 0) {
            frtList.push(vo.frtCd)
          }
        }
      })
      return frtList.length
    },
    helpClick (tp) {
      // $('#' + tp).show()
      if (tp === 'tip1') {
        this.isShowTip1 = !this.isShowTip1
      }

      if (tp === 'tip2') {
        this.isShowTip2 = !this.isShowTip2
      }
    },
    helpClose (tp) {
      $('#' + tp).hide()
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    closeFun () {
      this.$Simplert.close()
    },
    openNewPopup (compNm) {
      this.isShowPop = true
      this.customComponent = compNm
      $('#layerPop > .popup_dim').fadeIn()
      $('body,html').css('overflow', 'hidden')
    },
    async openPopup (compNm, kind, inx = 0, payNoData = '', payStsCdData = '') {
      if (kind !== 'PaymentRegPop' && kind !== 'PaymentDetailPop') {
        this.customComponent = compNm
      }

      // GA dataLayer 추가
      const checkedItems = gridView.getCheckedRows(true)
      if (checkedItems.length > 0) {
        checkedItems.forEach(idx => {
          const tmp = provider.getJsonRows(idx, idx)
          if (tmp.length > 0) {
            window.dataLayer.push({
              event: 'set_popup_info',
              popup_booking_no: tmp[0].bkgNo,
              popup_bl_no: tmp[0].blNo
            })
          }
        })
      }

      if (kind === 'FreightBillPop' || kind === 'TaxInvoicePop') {
        if (kind === 'FreightBillPop') {
          this.popBtnType = 'FREIGHT'
        } else if (kind === 'TaxInvoicePop') {
          // FreightBillPop 최초 오픈시 데이터 전달 안되서 수정, wmh
          this.customComponent = null
          this.popBtnType = 'TAX_INVOICE'
        }
        let checkList = gridView.getCheckedRows(true)
        if (checkList.length === 0) {
          this.openAlert(this.$t('msg.ADD20220219.008')) //BL을 선택해주세요.
          return false
        } else {
          await this.beforePopupCheck(checkList, '07', null)
        }
      } else if (kind === 'BL') { //B/L발행 팝업
        //TODO : 전용 벨리데이션 추가해야 함
        this.popBtnType = 'BL'
        this.openFreightPop()
      } else if (kind === 'DO') { //D/O발행 팝업
        //TODO : 전용 벨리데이션 추가해야 함
        this.popBtnType = 'DO'
        this.openFreightPop()
      } else if (kind === 'PaymentRegPop') { //Payment 팝업
        let checkList = gridView.getCheckedRows(true)
        this.openPaymentRegPop(checkList)
      } else if (kind === 'PaymentDetailPop') { //Payment 상세 팝업
        this.openPaymentDetailPop(inx, payNoData, payStsCdData)
      }
      if (kind !== 'FreightBillPop' && kind !== 'TaxInvoicePop' && kind !== 'BL' && kind !== 'DO' && kind !== 'PaymentRegPop') {
        this.isShowPop = true
        $('#layerPop > .popup_dim').fadeIn()
        $('body,html').css('overflow', 'hidden')
      }
    },
    async openPaymentRegPop (checkList) {
      if (!checkList.length) {
        this.openAlert(this.$t('msg.ADD20220219.008')) // BL을 선택해주세요.
      } else {
        let blArr = []
        let voArr = []
        let blStsArr = []
        let blStsStr = ''
        let blStsCd = true

        await checkList.forEach(idx => {
          const tmp = provider.getJsonRows(idx, idx)
          voArr.push(tmp)

          let isExist = false

          for (let bl of blArr) {
            if (bl.blNo === tmp[0].blNo) {
              isExist = true
            }
          }
          if (!isExist) {
            blArr.push(tmp[0])
          }

          if (!blStsArr.includes(tmp[0].blNo)) {
            blStsArr.push(tmp[0].blNo)
          }
        })

        // blStsArr.push('PUSG502187')

        this.blNoStrings = blStsArr.toString()

        await CalcIssue.getBlstatusList(this.blNoStrings)
        .then(res => {
          console.log('openpopup getBlstatusList res @@@@@@@ ', res)

          const blStatusList = res.data.blStatusList

          for (var sts of blStatusList) {
            if (sts.blStsCd === 'A5' || sts.blStsCd === 'A6' || sts.blStsCd === 'A7' || sts.blStsCd === '46') {
              blStsCd = false
            }
          }
        })

        if (!blStsCd) {
            this.openAlert('To continue the process, Please return first Original B/L to KMTC') // O.B/L 반환 안됨
            return
        }

        if (this.params.dtKnd === 'BL' && this.params.eiCatCd === 'I' && !this.isPermitDo(voArr, 'arr')) {
          this.openAlert(this.$t('msg.ADD20220219.002')) //'권한이 없는 B/L 입니다.'
          return
        }

        this.parentInfo.linkPayNo = ''
        this.parentInfo.linkYn = 'N'

        if (blArr.length === 1) {
          await payment.findPayReqYn({ blNo: blArr[0].blNo }).then(async response => {
            const payReqYn = response.data.payReqYn
            const houseBlStsCd = response.data.houseBlStsCd
            this.customComponent = null

            if (payReqYn === 'Y') {
              this.$ekmtcCommon.alertDefault('Payment request is received and in process.')
            } else {
              if (houseBlStsCd === '00') {
                if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.501'), useConfirmBtn: true })) {
                  this.customComponent = 'PaymentRegPop'
                  this.parentInfo.blNo = blArr[0].blNo
                  this.parentInfo.payStsCd = ''
                  this.parentInfo.paySubmitYn = 'N'
                  this.isShowPop = true
                  $('#layerPop > .popup_dim').fadeIn()
                  $('body,html').css('overflow', 'hidden')
                }
              } else if (houseBlStsCd === '03') {
                this.customComponent = 'PaymentRegPop'
                this.parentInfo.blNo = blArr[0].blNo
                  this.parentInfo.payStsCd = ''
                  this.parentInfo.paySubmitYn = 'N'
                this.isShowPop = true
                $('#layerPop > .popup_dim').fadeIn()
                $('body,html').css('overflow', 'hidden')
              } else {
                this.$ekmtcCommon.alertDefault('House B/L has not yet been approved.')
              }
            }
          })
        } else {
          // this.openAlert(this.$t('msg.ADD20220219.053')) // B/L을 1개만 선택해주세요.

          let payReqYn = ''
          let houseBlStsCd = ''
          this.customComponent = null
          let blstr = ''
          let isEnd = false
          let isIncludeHouseBl = false
          let isIncludeOtherBl = false
          let paymentAvailable = true

          for (let i = 0; i < blArr.length; i++) {
            if (i !== 0) {
              blstr += ':'
            }
            blstr += blArr[i].blNo

            await payment.findPayReqYn({ blNo: blArr[i].blNo }).then(async response => {
              payReqYn = response.data.payReqYn
              houseBlStsCd = response.data.houseBlStsCd

              if (payReqYn === 'Y') {
                paymentAvailable = false
                isEnd = true
              } else {
                if (houseBlStsCd === '00') {
                  isIncludeHouseBl = true
                } else if (houseBlStsCd === '03') {
                  // this.customComponent = 'PaymentRegPop'
                  // this.parentInfo.blNo = blArr[i].blNo
                  // this.parentInfo.payStsCd = ''
                  // this.parentInfo.paySubmitYn = 'N'
                  // this.isShowPop = true
                  // $('#layerPop > .popup_dim').fadeIn()
                  // $('body,html').css('overflow', 'hidden')
                } else {
                  isIncludeOtherBl = true
                  paymentAvailable = false
                  isEnd = true
                }
              }
            })

            if (isEnd) {
              break
            }
          }

          if (payReqYn === 'Y') {
            this.$ekmtcCommon.alertDefault('Payment request is received and in process.')
          } else if (isIncludeOtherBl) {
            this.$ekmtcCommon.alertDefault('House B/L has not yet been approved.')
          } else if (isIncludeHouseBl) {
            if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.501'), useConfirmBtn: true })) {
              this.customComponent = 'PaymentRegPop'
              this.parentInfo.blNo = blstr
              this.parentInfo.payStsCd = ''
              this.parentInfo.paySubmitYn = 'N'
              this.isShowPop = true
              $('#layerPop > .popup_dim').fadeIn()
              $('body,html').css('overflow', 'hidden')
            }
          } else if (paymentAvailable) {
            this.customComponent = 'PaymentRegPop'
            this.parentInfo.blNo = blstr
            this.parentInfo.payStsCd = ''
            this.parentInfo.paySubmitYn = 'N'
            this.isShowPop = true
            $('#layerPop > .popup_dim').fadeIn()
            $('body,html').css('overflow', 'hidden')
          }
        }
      }
    },
    async openPaymentDetailPop (inx, payNoData, payStsCdData) {
      let blArr = []
      let voArr = []

      const tmp = provider.getJsonRows(inx, inx)
      voArr.push(tmp)
      blArr.push(tmp[0])

      if (this.params.dtKnd === 'BL' && this.params.eiCatCd === 'I' && !this.isPermitDo(voArr, 'arr')) {
        this.openAlert(this.$t('msg.ADD20220219.002')) //'권한이 없는 B/L 입니다.'
        return
      }

      if (blArr.length === 1) {
        this.customComponent = 'PaymentRegPop'
        this.parentInfo.blNo = blArr[0].blNo
        this.parentInfo.linkPayNo = payNoData
        this.parentInfo.payStsCd = payStsCdData
        this.parentInfo.linkYn = 'Y'
        this.isShowPop = true
        $('#layerPop > .popup_dim').fadeIn()
        $('body,html').css('overflow', 'hidden')
      } else {
        this.openAlert(this.$t('msg.ADD20220219.053')) // B/L을 1개만 선택해주세요.
      }
    },
    async doRequest () {
      const checkedItems = gridView.getCheckedRows(true)

      if (!checkedItems.length) {
        this.openAlert(this.$t('msg.ADD20220219.008')) // BL을 선택해주세요.
      } else {
        let blArr = []
        let payArr = []
        let voArr = []

        checkedItems.forEach(idx => {
          const tmp = provider.getJsonRows(idx, idx)
          voArr.push(tmp)

          let isExist = false
          for (let bl of blArr) {
            if (bl.blNo === tmp[0].blNo) {
              isExist = true
            }
          }
          if (!isExist) {
            blArr.push(tmp[0])
          }

          // GA dataLayer 추가
          window.dataLayer.push({
            event: 'set_popup_info',
            popup_booking_no: tmp[0].bkgNo,
            popup_bl_no: tmp[0].blNo
          })
        })

        checkedItems.forEach(idx => {
          const tmp = provider.getJsonRows(idx, idx)

          let isExist = false
          for (let pay of payArr) {
            if (pay.payNo === tmp[0].payNo) {
              isExist = true
            }
          }
          if (!isExist) {
            payArr.push(tmp[0])
          }
        })

        if (this.params.dtKnd === 'BL' && this.params.eiCatCd === 'I' && !this.isPermitDo(voArr, 'arr')) {
          this.openAlert(this.$t('msg.ADD20220219.002')) //'권한이 없는 B/L 입니다.'
          return
        }

        if (blArr.length === 1) {
          const blNo = blArr[0].blNo
          const payNo = payArr[0].payNo
          const evdcmtrUpldYn = blArr[0].evdcmtrUpldYn
          const payDoStCd = blArr[0].payDoStCd

          if (payDoStCd === '1') {
            this.openAlert('D/O already issued.')
            return
          }
          if (payDoStCd === '2') {
            this.openAlert('D/O request has been done.')
            return
          }
          if (evdcmtrUpldYn !== 'Y') {
            this.openAlert(this.$t('msg.CSBK100.500'))
            return
          }

          if (await this.$ekmtcCommon.asyncAlertMessage({ message: 'Would you like to request D/O?', useConfirmBtn: true })) {
            payment.doReqSave({ blNo: blNo, payNo: payNo }).then(response => {
              const respCode = response.headers.respcode

              if (respCode && respCode === 'C0000') {
                this.$ekmtcCommon.alertDefault('Completed to save.')
                this.searchData()
              } else {
                this.$ekmtcCommon.alertDefault('Failed to save.')
              }
            })
          }
        } else {
          this.openAlert(this.$t('msg.ADD20220219.053')) // B/L을 1개만 선택해주세요.
        }
      }
    },
    openFreightPop () {
      let checkList = gridView.getCheckedRows(true)
      let blList = []
      let page = parseInt(gridView.getPage())
      let selectedBl = ''
      let invIssNoArrStr = ''
      let invRnoArrStr = ''
      let invIssNoList = []
      let invRnoList = []
      let selectedBlIdx = -1
      let startBlSelectedIdx = -1
      let blListIdx = -1
      checkList.forEach(idx => {
        const tmp = provider.getJsonRows(idx, idx)

        if (tmp.length > 0) {
          console.log('tmp.length > 0')
          const bl = tmp[0]

          let isExist = false

          for (let invIssNo of invIssNoList) {
            if (invIssNo === bl.invIssNo) {
              isExist = true

              break
            }
          }

          if (!isExist) {
            invIssNoArrStr += (invIssNoArrStr === '' ? bl.invIssNo : (',' + bl.invIssNo))
            invIssNoList.push(bl.invIssNo)
          }

          isExist = false

          for (let invRno of invRnoList) {
            if (invRno === bl.invRno) {
              isExist = true

              break
            }
          }

          if (!isExist) {
            invRnoArrStr += (invRnoArrStr === '' ? bl.invRno : (',' + bl.invRno))
            invRnoList.push(bl.invRno)
          }

          // PAID된 것과 Y인 것들을 제외하고는 넘어가도록 변경
          // if (bl.issStstus === 'N') { // 세금계산서 발행 안된 건
          if (bl.issStstus !== 'Paid' && bl.issStstus !== 'Y') { // 세금계산서 발행 안된 건
            let krw = 0
            if (bl.occrCurCd === 'KRW') {
              krw = parseInt(bl.occrAmt)
            } else if (bl.occrCurCd === 'USD') {
              //
            } else {
              krw = parseInt(bl.occrAmt) * parseFloat(bl.exRate)
              bl.occrCurCd = 'KRW'
            }
            // blList.push({
            //   blNo: bl.blNo,
            //   invIssNo: !this.$_.isEmpty(bl.invIssNo) ? bl.invIssNo : null,
            //   invRno: !this.$_.isEmpty(bl.invRno) ? bl.invRno : null,
            //   krw: krw,
            //   usd: bl.occrCurCd === 'USD' ? parseInt(bl.occrAmt) : 0,
            //   exRate: bl.occrCurCd === 'KRW' ? 1 : parseFloat(bl.exRate),
            //   currency: bl.occrCurCd === 'KRW' ? 'KRW' : 'KRW+USD',
            //   obrdDt: bl.obrdDt,
            //   eiCatCd: this.params.eiCatCd,
            //   cntrList: []
            // })
            blList.push({
              blNo: bl.blNo,
              invIssNo: invIssNoArrStr !== '' ? invIssNoArrStr : null,
              invRno: invRnoArrStr !== '' ? invRnoArrStr : null,
              krw: krw,
              usd: bl.occrCurCd === 'USD' ? parseInt(bl.occrAmt) : 0,
              exRate: bl.occrCurCd === 'KRW' ? 1 : parseFloat(bl.exRate),
              currency: bl.occrCurCd === 'KRW' ? 'KRW' : 'KRW+USD',
              obrdDt: bl.obrdDt,
              eiCatCd: this.params.eiCatCd,
              cntrList: []
            })

            blListIdx++

            selectedBlIdx = blListIdx

            if (selectedBl !== bl.blNo) {
              selectedBl = bl.blNo
              invIssNoArrStr = ''
              invRnoArrStr = ''
              invIssNoList = []
              invRnoList = []

              if (startBlSelectedIdx !== -1) {
                for (let i = startBlSelectedIdx; i < selectedBlIdx - 1; i++) {
                  const ttmpBl = blList[selectedBlIdx - 1]

                  blList[i].invIssNo = ttmpBl.invIssNo
                  blList[i].invRno = ttmpBl.invRno
                }
              }

              startBlSelectedIdx = blListIdx
            } else if (checkList.length - 1 === idx && checkList.length - 1 !== startBlSelectedIdx) {
              if (startBlSelectedIdx !== -1) {
                for (let i = startBlSelectedIdx; i < selectedBlIdx; i++) {
                  const ttmpBl = blList[selectedBlIdx]

                  blList[i].invIssNo = ttmpBl.invIssNo
                  blList[i].invRno = ttmpBl.invRno
                }
              }
            }
          }
        }
      })
      blList = this.removeDuplicatedBlList(blList)

      this.changeCurDmdCatCd()

      this.freightBillInfo = {
        dpId: 'TAX_INVOICE',
        //dmdCatCd: 'C', //화주구분 : 말화주(M), 청구화주(C), 영수화주(R)
        dmdCatCd: this.curDmdCatCd,
        eiCatCd: this.params.eiCatCd,
        btnType: this.popBtnType, //FREIGHT: 운임수납, TAX_INVOICE: 계산서발행, BL: BL 발행, DO: DO발행, BOOKING_CANCEL : BOOKING CANCEL
        hasNotPaid: true, //TODO 미정산 금액 존재여부
        hasNotTaxInvoice: true, //TODO 세금계산서 미발행 건 존재 여부
        blList: blList
      }
      this.isShowPop = true
      this.customComponent = 'FreightBillPop'
      $('#layerPop > .popup_dim').fadeIn()
      $('body,html').css('overflow', 'hidden')
    },
    removeDuplicatedBlList (blList) {
      let res = []
      let isExist = false
      for (let bl of blList) {
        isExist = false
        for (let r of res) {
          if (r.blNo === bl.blNo) {
            isExist = true
            if (bl.currency === 'KRW') {
              r.krw += bl.krw
            } else if (bl.currency === 'KRW+USD') {
              r.usd += bl.usd
              r.exRate = bl.exRate
              r.currency = 'KRW+USD'
            }
            break
          }
        }
        if (!isExist) {
          res.push(bl)
        }
      }

      return res
    },
    closePoup (vo) {
      this.customComponent = null
      this.isShowPop = false
      $('#layerPop > .popup_dim').fadeOut()
      $('body,html').removeAttr('style')

      // 데이터 재조회
      if (vo && vo.saveFlag) {
        this.searchData('closeResearch')
      }
    },
    async openBlCopyPop () {
      // B/L Copy
      this.invoiceParam = {
        testCtrCd: '',
        testBkgPlcCd: '',
        testUserId: '',
        items: []
      }

      let rows = gridView.getCheckedRows(true)
      if (rows.length === 0) {
        this.openAlert(this.$t('msg.ADD20220219.009')) // '출력 대상에 추가할 B/L을 선택해주세요.'
        return
      }

      const tmpData = []
      const arrCheck = []
      let collectBl = []

      rows.forEach(idx => {
        const rowData = provider.getJsonRows(idx, idx)
        if (rowData.length > 0) {
          const vo = rowData[0]
          const blInfo = {
            blNo: vo.blNo,
            porDlyYn: 'N',
            noCnee: (this.params.eiCatCd === 'I' ? 'N' : 'Y'),
            noNotify: (this.params.eiCatCd === 'I' ? 'N' : 'Y')
          }
          const isDuplicat = tmpData.filter(v => v.blNo === vo.blNo)
          console.log(vo)
          if (isDuplicat.length === 0) {
            if (this.params.eiCatCd === 'I') {
                if (vo.authImBl === 'A' || this.memberDetail.staffFlag === 'Y') {
                    tmpData.push(blInfo)
                    arrCheck.push({ blNo: vo.blNo, bkgNo: vo.bkgNo, userId: this.auth.userId })
                } else {
                    collectBl.push(vo)
                }
            } else {
              tmpData.push(blInfo)
              arrCheck.push({ blNo: vo.blNo, bkgNo: vo.bkgNo, userId: this.auth.userId })
            }
          }

          // GA dataLayer 추가
          window.dataLayer.push({
            event: 'set_popup_info',
            popup_booking_no: vo.bkgNo,
            popup_bl_no: vo.blNo
          })
        }
      })

      const params = {
        searchList: []
      }
      params.searchList = arrCheck
      let isCheck = true

      if (this.params.eiCatCd === 'I') {
        console.log('param >>>> , , ', params)
        if (params.searchList.length > 0) {
          await importSvc.getOnGoingImportRequestBtn(params).then(res => {
            const authInfo = res.data
            if (authInfo.report02 !== 'Y') {
              isCheck = false
              this.openAlert(this.$t('art.CMATK232')) // 권한이 없습니다.
            }
          })
        } else {
          isCheck = false
          this.openAlert(this.$t('msg.ADD20220219.002')) //권한이 없는 B/L 입니다.
        }
      } else {
        await exportSvc.getOnGoingRequestBtn(params).then(res => {
          const authInfo = res.data
          if (authInfo.report04 !== 'Y') {
            isCheck = false
            this.openAlert(this.$t('art.CMATK232')) // 권한이 없습니다.
          }
        })
      }

      if (!isCheck) {
        return
      }
      console.log('tmpData', tmpData)
      this.invoiceParam.items = tmpData
      this.custCompInvoice = 'BlCopyPop'
      $('#layerInvoice > .popup_dim').fadeIn()
      $('body,html').css('overflow', 'hidden')
    },
    async invoicePublish (data) {
      let rtn = []
      try {
        await CalcIssue.invoicePublish(data)
          .then(response => {
            console.log('Invoice 번호 생성 결과 >>')
            console.log(response)
            if (response.data) {
              rtn = response.data
            }
          })
      } catch (e) {
        console.log(e)
      } finally {
        console.log('!종료')
      }
      return rtn
    },
    // 사유팝업
    openMsgReason (e, inx) {
      e.preventDefault()
      if (inx > -1) {
        const tmp = provider.getJsonRows(inx, inx)
        if (tmp.length > 0) {
          if (this.params.eiCatCd === 'O') {
            // 인도네시아 서렌더, 오비엘 상태 체크
            // if ((tmp[0].blStCd === '01' || tmp[0].blStCd === '11') && tmp[0].rejectUno !== '' && this.memberDetail.userCtrCd === 'ID') {
            //   tmp[0].blStCd = '88'
            // }

            this.detail = tmp[0]

            this.customComponent = 'CalcIssueBlMsgPop'
          } else {
            this.detail = tmp[0]
            this.customComponent = 'CalcIssueDoMsgPop'
          }

          // 팝업 콜백
          this.popCallback = (p) => {
            console.log('---openMsgReason---')
            console.log(this.detail)
            console.log('---/openMsgReason---')
          }

          this.isShowPop = true
          $('#layerPop > .popup_dim').fadeIn()
          $('body,html').css('overflow', 'hidden')
        }
      }
    },
    excelDownload () {
      const curDt = moment().format('YYYYMMDD')
      gridView.exportGrid({
        type: 'excel',
        target: 'local',
        documentTitle: { //제목
          message: this.$t('art.CMATK329') + this.$t('msg.SPEC030.030'), // '발행 조회',
          visible: true,
          spaceTop: 1,
          spaceBottom: 1,
          height: 60,
          styleName: 'excel-export-title-style'
        },
        applyDynamicStyles: true,
        exportTemplate: true,
//        allColumns: true,
        pagingAllItems: true,
        fileName: `${this.$t('art.CMATK329') + this.$t('msg.SPEC030.030')}_${curDt}.xlsx`,
        showProgress: false,
        progressMessage: this.$t('msg.ADD20220219.012'), // 엑셀 Export중입니다.
        indicator: 'default',
        header: 'default',
        footer: 'default',
        done: function () {
          console.log('엑셀 다운로드 완료')
        }
      })
    },
    async fnActionRun (type) {
      const checkedItems = gridView.getCheckedRows()
      let voArr = []

      if (checkedItems.length === 0) {
        this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONGO080G010.024') }) // 대상을 선택해 주세요.
        return
      } else if (checkedItems.length > 1 && type === 'FRT') {
        this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONGO080G010.031') }) // 대상을 1개만 선택해 주세요.
        return
      }

      // 사전 검증
      let arrBlNo = []
      const arrCheck = []
      const arrIsFwdrYn = []
      let issStstus = ''

      if (type === 'BL_ISSUE') {
        checkedItems.forEach(idx => {
          const rowData = provider.getJsonRows(idx, idx)
          if (rowData.length > 0) {
            const vo = rowData[0]
            voArr.push(vo)
            const isDuplicat = arrBlNo.filter(v => v === vo.blNo)
            console.log('BL_ISSUE isDuplicat ::: ', isDuplicat)

            if (isDuplicat.length === 0) {
              arrBlNo.push(vo.blNo)
              arrCheck.push({ blNo: vo.blNo, userId: this.auth.userId })
              arrIsFwdrYn.push({ blNo: vo.blNo, fwdrCstNo: vo.fwdrCstNo, isFwdrYn: vo.isFwdrYn, cstCd: this.memberDetail.cstCd, userCtrCd: this.memberDetail.userCtrCd })

              // 세금계산서 발행상태 변수 추가 전달
              issStstus = vo.issStstus
            }
          }
        })

        console.log('BL_ISSUE issStstus ::: ', issStstus)

        for (let vo of voArr) {
          if (!this.isPermitIssueBlBtn(vo)) {
            return
          }
        }
        //B/L발행 클릭 시 자동 Initialize Amount
        await CalcIssue.createInitialize(arrBlNo).then(result => {
        console.log('Initialize Amount')
        }).catch(e => {
          console.log(e)
        })
      }

      let chkDoPayment = {}
      let chkDoSt = []

      if (type === 'DO_ISSUE' || type === 'DO_PRINT') {
        let collectBl = []

        if (type === 'DO_ISSUE' && this.isGridCheckData() > 1) {
          this.openAlert(this.$t('msg.ADD20220219.001')) // 'FREIGHT와 DEM/DET/OFC는 과세구분이 상이하여 개별로 진행바랍니다.'
          return
        }

        checkedItems.forEach(idx => {
          const rowData = provider.getJsonRows(idx, idx)

          if (rowData.length > 0) {
            const vo = rowData[0]
            voArr.push(vo)
            const isDuplicat = arrBlNo.filter(v => v === vo.blNo)

            if (isDuplicat.length === 0) {
              if (type === 'DO_PRINT') {
                if (vo.frtPncCdDo === 'P') { // prepaid BL
                  arrBlNo.push(vo.blNo)
                  arrCheck.push({ blNo: vo.blNo, userId: this.auth.userId })
                  chkDoSt.push(vo)
                } else if (vo.frtPncCdDo === 'C') { // Collect BL 일때, INVOICE 출력 가능 경우 조건 추가
                    if (vo.authImDo === 'A' || vo.authImDo === 'P') {
                        arrBlNo.push(vo.blNo)
                        arrCheck.push({ blNo: vo.blNo, userId: this.auth.userId })
                        chkDoSt.push(vo)
                    } else {
                        collectBl.push(vo)
                    }
                }
              } else {
                 arrBlNo.push(vo.blNo)
                 arrCheck.push({ blNo: vo.blNo, userId: this.auth.userId })

                 if (vo.frtPncCdDo === 'C' && vo.authImInv !== 'A') { // collect BL
                    collectBl.push(vo)
                 }
              }
            }
          }
        })

        let doPrintYlist = []
        let doPrintNlist = []
        let sendResult = ''

        // D/O 출력에서 D/O 발행된 건만 출력가능
        if (type === 'DO_PRINT') {
          for (var i = 0; i < chkDoSt.length; i++) {
            await doapi.checkDoPrint({ blNo: chkDoSt[i].blNo })
            .then(res => {
              console.log('DO_PRINT res >>>> ', res)
              sendResult = res.data

              if (sendResult === '1' || sendResult === '2') {
                doPrintYlist.push(chkDoSt[i])
              } else {
                doPrintNlist.push(chkDoSt[i])
              }
            })
          }

          if (doPrintYlist.length > 0) { // 체크된 BL이 여러건 일때, D/O 발행 안된 건 제외
            chkDoSt = doPrintYlist
          }
        }

        if (doPrintNlist.length > 0 && doPrintYlist.length === 0) {
          console.log('sendResult >>>> ', sendResult)
          this.openAlert(this.$t('msg.DOIssue.025')) // D/O 발행건만 출력가능합니다.
          return
        }

        chkDoPayment = {
          collectBl: collectBl
        }

        const params = {
          searchList: []
        }
        params.searchList = arrCheck
        let isCheck = true
        let noPrintMsg = ''

        if (params.searchList.length > 0) {
          await importSvc.getOnGoingImportRequestBtn(params).then(res => {
            const authInfo = res.data

            if (type === 'DO_PRINT' && authInfo.report06 !== 'Y') {
              isCheck = false

              chkDoSt.forEach(el => {
                if (el.doStCd === '11') {
                  noPrintMsg = this.$t('msg.ADD20220221.016') // 종이 D/O를 발급하였습니다. 홈페이지에서 출력 불가합니다.
                } else if (el.doStCd === '12') {
                  noPrintMsg = this.$t('msg.ADD20220221.018') // KT-NET으로 D/O 승인되었습니다. KL-NET에서 출력 가능합니다.
                } else if (el.doStCd === '13') {
                  noPrintMsg = this.$t('msg.ADD20220221.017') //KL-NET으로 D/O 승인되었습니다. KT-NET에서 출력 가능합니다.
                } else {
                  noPrintMsg = this.$t('msg.ONEX010T010.078') + this.$t('msg.ONEX010T010.154')
                }
              })
              this.openAlert(noPrintMsg)
            }
          })
        } else {
          isCheck = false
          this.openAlert(this.$t('msg.ADD20220219.002')) //권한이 없는 B/L 입니다.
        }

        if (!isCheck) {
          return
        }
      } else {
        checkedItems.forEach(idx => {
          const rowData = provider.getJsonRows(idx, idx)

          if (rowData.length > 0) {
            const vo = rowData[0]
            voArr.push(vo)

            if (this.params.dtKnd === 'BL' && this.params.eiCatCd === 'I' && ['JP', 'VN', 'TH', 'MY', 'PH'].indexOf(this.memberDetail.userCtrCd) < 0) {
              if (this.isPermitDo(vo.actShprCstEnm, 'enm')) {
                const isDuplicat = arrBlNo.filter(v => v === vo.blNo)
                if (isDuplicat.length === 0) {
                  arrBlNo.push(vo.blNo)
                }
              }
            } else {
              const isDuplicat = arrBlNo.filter(v => v === vo.blNo)
              if (isDuplicat.length === 0) {
                arrBlNo.push(vo.blNo)
              }
            }
          }
        })

        if (this.params.dtKnd === 'BL' && this.params.eiCatCd === 'I' && ['JP', 'VN', 'TH', 'MY', 'PH'].indexOf(this.memberDetail.userCtrCd) < 0) {
          if (!this.isPermitDo(voArr, 'arr')) {
            this.openAlert(this.$t('msg.ADD20220219.002')) //권한이 없는 B/L 입니다.
            return
          }
        }
      }
      if (arrBlNo.length === 0 && ['JP', 'VN', 'TH', 'MY', 'PH'].indexOf(this.memberDetail.userCtrCd) < 0) {
        this.openAlert(this.$t('msg.ADD20220219.010')) //'출력 대상에 B/L이 존재하지 않습니다.'
        return
      }
      console.log('B/L No List >> ', arrBlNo)
      if (type === 'SETTLE') { // 운임수납
        this.$refs.commonUnsolved.openFreightPop(arrBlNo, 'FREIGHT', this.params.eiCatCd) // 수출: O, 수입: I
      } else if (type === 'TAX') { // 계산서 발행
        this.$refs.commonUnsolved.openFreightPop(arrBlNo, 'TAX_INVOICE', this.params.eiCatCd) // 수출: O, 수입: I
      } else if (type === 'FRT') { // 운임 수정 요청
        this.$refs.commonUnsolved.freightPopCall(arrBlNo[0])
      } else if (type === 'BL_ISSUE') {
        // this.$refs.commonUnsolved.openBlPaymentPop(arrBlNo, 'O')
        this.$refs.commonUnsolved.openBlPaymentPop(arrBlNo, 'O', arrIsFwdrYn, issStstus)
      } else if (type === 'DO_ISSUE') {
        console.log('openDoPaymentPop DO_ISSUE')
        console.log(arrBlNo)
        this.$refs.commonUnsolved.openDoPaymentPop(arrBlNo, 'I', chkDoPayment)
      } else if (type === 'DO_PRINT') {
          console.log('openDoPaymentPop DO_PRINT')
          this.$refs.commonUnsolved.DeliveryOrderPop(arrBlNo)
      } else if (type === 'SRD') {
          console.log('===========================SRD================================')
          this.$refs.commonUnsolved.openSrdPop(arrBlNo, 'O', arrIsFwdrYn, issStstus)
      } else if (type === 'DO_REQUEST') {
          console.log('===========================DO_REQUEST================================')
          this.$refs.commonUnsolved.openDORequestEtcPop(arrBlNo, 'I', voArr, issStstus)
      } else {
        alert(type)
      }
    },
    async fnAction (type) {
      const checkedItems = gridView.getCheckedRows()

      // GA dataLayer 추가
      if (checkedItems.length > 0) {
        checkedItems.forEach(idx => {
          const tmp = provider.getJsonRows(idx, idx)
          if (tmp.length > 0) {
            window.dataLayer.push({
              event: 'set_popup_info',
              popup_booking_no: tmp[0].bkgNo,
              popup_bl_no: tmp[0].blNo
            })
          }
        })
      }

      if (type === 'DO_ISSUE') {
        // DO발행일 경우 계산서 체크하기 전에 Status 체크를 먼저 하도록 변경
        const statusValid = await this.statusValidationCheck(checkedItems, '14', type)
        if (!statusValid) {
          return false
        } else {
          this.beforePopupCheck(checkedItems, '14', type)
        }
      } else {
        this.fnActionRun(type)
      }
    },
    changeDmd () {
      if (this.isCheckDmd) {
        this.params.asChkDmd = 'Y'
      } else {
        this.params.asChkDmd = 'N'
      }
    },
    isOwnerOrAgent (ownerCstNo, agencyTaskCnt) {
      if (ownerCstNo === this.auth.cstCd || agencyTaskCnt > 0) {
        return true
      }
      return false
    },
    isPermitDo (data, type) {
      let isPermit = true

      if (type === 'arr') {
        for (let vo of data) {
          if (vo instanceof Array) {
            if (vo[0].actShprCstEnm === '**') {
              isPermit = false
            }
          } else {
            if (vo.actShprCstEnm === '**') {
              isPermit = false
            }
          }
        }
      } else if (type === 'enm') {
         if (data === '**') {
            isPermit = false
          }
      }

      return isPermit
    },
    isPermitIssueBlBtn (vo) {
      let rtnMsg = this.$t('msg.ADD20220219.013') + '\n' //'B/L 발행 요청이 불가능합니다.\n'
      let isOk = true

      if (vo.blStCd === '99') {
        if (this.memberDetail.userCtrCd !== 'JP' && vo.cntrStsCd === 'N' && vo.elYn === 'N') {
          isOk = false
        } else if (this.memberDetail.userCtrCd !== 'JP' && vo.elYn === 'N') {
          isOk = false
        } else if (this.memberDetail.userCtrCd !== 'JP' && vo.cntrStsCd === 'N' && vo.cntrCfsYn === 'N') {
          isOk = false
        } else {
          isOk = true
        }
      }

      if (!isOk) {
        this.openAlert(rtnMsg)
      }

       return isOk
    },
    combineInvoicePopCall (items, eiCatCd) {
      const params = {
        eiCatCd: eiCatCd,
        items: items,
        chkYn: 'Y'
      }

      // console.log('combineInvoicePopCall ::: ', params)
      this.openInvPopup('CombineInvoicePop', params)
    },
    openInvPopup (compNm, params) {
      this.popupParams = params
      this.popupComponent = compNm
      this.$ekmtcCommon.layerOpen('.inv_ongoing_list_popup')
    },
    closeInvPopup () {
      this.popupParams = {}
      this.popupComponent = null
      this.popupCallback = ''
      this.$ekmtcCommon.layerClose('.inv_ongoing_list_popup')
    },
    kmtcCouponPopCall () {
      const params = {
        items: this.couponList
      }
      return new Promise(resolve => {
        this.closeCouponCallback = onCouponCallback
        function onCouponCallback () {
          resolve(true)
        }
        this.openCouponPopup('KmtcCouponPop', params)
      })
    },
    openCouponPopup (compNm, params) {
      this.couponPopupParams = params
      this.couponComponent = compNm
      this.$ekmtcCommon.layerOpen('.coupon_popup')
    },
    closeCouponPopup () {
      this.couponPopupParams = {}
      this.couponComponent = null
      this.$ekmtcCommon.layerClose('.coupon_popup')

      this.closeCouponCallback()
    },
    closeCouponCallback () {
      return true
    },
    checkInvoiceData (data) {
      let arrayBlNo = []
      let isOk = true

      if (this.invoiceData.length === 0) {
        this.openAlert(this.$t('msg.DOIssue.003')) // Invoice를 출력할 B/L이 없습니다.
        return
      }

      this.invoiceData.forEach(v => {
        if (this.$_.trim(v.invIssNo) !== '') {
          const invoceInfo = {
            invIssNo: v.invIssNo,
            to: '',
            remark: '',
            filter: 'BL',
            blNo: v.blNo,
            esign: ''
          }
          arrayBlNo.push(invoceInfo)
        }

        if (this.$_.trim(v.invIssNo) === '' && (this.$_.trim(v.invRno) !== '' || this.$_.trim(v.invRno1) !== '')) {
          const blInfo = {
            blNo: v.blNo,
            invRno: v.invRno,
            index: v.reqCnt,
            mainYn: 'N'
          }
          arrayBlNo.push(blInfo)
        }
      })

      if (arrayBlNo.length === 0) {
        isOk = false
      }

      return isOk
    },
    async beforePopupCheck (checkList, type, fnType) {
      let blList = ''
      let i = 0
      let isIssued = true
      let isStoped = true

      checkList.forEach(idx => {
        const tmp = provider.getJsonRows(idx, idx)
        console.log('beforePopupCheck checkList tmp >>> ', tmp)

        if (tmp.length > 0) {
          const bl = tmp[0]
          if (i > 0) {
            blList += ','
          }
          blList += bl.blNo

          if (bl.issStstus === 'N') { // 미발행
            isIssued = false
          }
        }
        i++
      })

        CalcIssue.checkHasCm134IType(blList, type).then(response => {
          console.log('checkHasCm134IType 2>>> ', response)

          let data = response.data
          if (response.data.length > 0) {
            if (!isIssued) { // 여러건들중 미발행 계산서 있을경우
              this.checkBl()
            } else {
              //**DO발행인경우에는 계산서발행여부를 체크, 계산서 발행인 경우에는 DO발행요청여부를 체크. (통테 요구사항)
              if (type === '07') {
                this.openAlert(this.$t('msg.ADD20220219.016')) //'D/O 발행 요청 중입니다.' 처리 후 신청 가능합니다.
              } else if (type === '14') {
                if (data[0].apvStsCd === '02' || data[0].apvStsCd === '07') { // 요청중, PENDING
                  this.openAlert(this.$t('msg.ONIM010T010.017')) // '계산서 발행 중입니다.'
                } else {
                  this.fnActionRun(fnType)
                }
              }
            }
          } else {
            if (type === '07') {
              this.checkBl()
            } else if (type === '14') {
              this.fnActionRun(fnType)
            }
          }
        }).catch(e => {
          console.log(e)
          return false
        })
    },
    closeInvoce () {
      this.invoiceParam = {}
      this.isInvoiceShowPop = false
      this.custCompInvoice = null
      $('#layerInvoice > .popup_dim').fadeOut()
      $('body,html').removeAttr('style')
    },
    getCodeNm (cdId, cd) {
      const cdList = this.commonCodes[cdId]
      if (cdList === undefined) {
        return cdId + '_' + cd
      }

      const cdVo = cdList.find(vo => vo.cd === cd)
      if (cdVo === undefined) {
        return cd
      }
      return cdVo.cdNm
    },
    async getBlPaidCont (e, cont, blNo, id) {
      const key = 'BLTAX_' + id
      let tempList = this.tempData[key]

      if (tempList === undefined) {
        const params = {
          blNo: blNo
        }

        await exportSvc.getOnGoingBlTax(params).then(async res => {
            tempList = res.data
            console.log('getOnGoingBlTax >>> ', res)
            this.tempData[key] = tempList
          })
      }

      let msg = ''
      let html = []
      if (tempList !== undefined && tempList.length > 0) {
        const ttl1 = this.$t('msg.ONEX010T010.117') // 계산서번호
        const ttl2 = this.$t('msg.ONEX010T010.118') // 금액
        const ttl3 = this.$t('msg.ONEX010T010.119') // 상태
        const ttl4 = this.$t('msg.ONEX010T010.120') // 발급 Email
        const ttl5 = this.$t('msg.ONEX010T010.121') // 입금표번호

        console.log('tempList >>> ', tempList)

        tempList.forEach((tvo, idx) => {
          if (this.auth.serviceLang === 'KOR') {
            html.push(`${ttl1} : ${tvo.acshRno || ''}`)
            html.push(`${ttl2} : ${this.$ekmtcCommon.changeNumberFormat(tvo.slprcAmt || '0', { isComma: true })}`)
            html.push(`${ttl3} : ${this.getCodeNm('01113', tvo.snreStsCd)}`)
            html.push(`${ttl4} : ${tvo.recptEmlAddr || ''}`)

            if (tempList.length > 1) {
              html.push('\n')
            }
          } else {
            html.push(`${ttl5} : ${tvo.rcvRno || ''}`)
            html.push(`${ttl2} : ${this.$ekmtcCommon.changeNumberFormat(tvo.slprcAmt || '0', { isComma: true })}`)
          }
        })

        msg = html.join('<br/>')
      }

      this.$ekmtcCommon.asyncAlertMessage({ message: msg })
    },
    async getDoPaidCont (idx, frtInfo, frtInfoId, ctrCd) {
      let tmp = provider.getJsonRows(idx, idx)
      let temp = this.rstList.filter(v => v.blNo === tmp[0].blNo && v.issStstus === tmp[0].issStstus)
      let curCd = this.$t('msg.ONEX070G100.018') // 원
      let pMsg = ''
      let msg = ''
      let html = []
      let frtInfoArr = []
      let arrPayInfo = []
      let arrTooltip = []

      if (ctrCd === 'ID') { // 인도네시아
        if (frtInfoId !== undefined) {
          arrPayInfo.push(`<p class="color_blue2 font_11 mt5">Payment No. ('${evdcmtrUpldYn}')</p>`)
          pMsg = arrPayInfo.join('')
        }

        if (frtInfo !== undefined) {
          arrTooltip.push(frtInfo) //세금계산서 번호
          msg = arrTooltip.join('<br/>')
        }

        if (pMsg !== undefined) {
          msg = pMsg + '\n' + msg
        }

        this.$ekmtcCommon.asyncAlertMessage({ message: msg })
      } else {
        if (frtInfo !== undefined) {
          frtInfoArr = frtInfo.split('/')
        }

        console.log('getDoPaidCont frtInfoArr >>>> ', frtInfoArr)

        if (frtInfoArr !== undefined) {
          const acshLen = frtInfoArr[4] === 0 ? '' : frtInfoArr[4] - 1
          html.push(`${app.$t('msg.ONIM010T010.001')} : ` + frtInfoArr[1] + (acshLen > 0 ? `외 ${acshLen}개` : '')) //세금계산서 번호
          html.push(`${app.$t('msg.CSDBL400_M2.0042')} : ` + vmApp.$ekmtcCommon.changeNumberFormat(frtInfoArr[0], { isComma: true }) + curCd) // 금액
          html.push(`${app.$t('msg.MAIN100.206')} : ` + frtInfoArr[3]) // 상태
          html.push(`${app.$t('msg.SETT010T010.031')} ${app.$t('msg.CMBA100.00163')} : ` + frtInfoArr[2]) // 발급 e-mail

          if (frtInfoArr.length > 4) {
            html.push('\n')
          }
        }

        msg = html.join('<br/>')

        this.$ekmtcCommon.asyncAlertMessage({ message: msg })
      }
    },
    getRejectCont (e, idx, value) {
      e.preventDefault()
      let blNo = provider.getJsonRows(idx, idx)[0].blNo
      let issStstus = provider.getJsonRows(idx, idx)[0].issStstus
      let val = value

        CalcIssue.getRejectReason(blNo).then(response => {
          let resp = response.data

          if (!this.$_.isEmpty(resp)) {
            let rejectRsps = resp.reqRsps === undefined ? '' : resp.reqRsps // 거절 사유
            let rejectUsrNm = resp.usrNm === undefined ? '' : resp.usrNm // 응답 담당자
            let rejectUno = resp.uno === undefined ? '' : resp.uno // 응답 담당자 영문이름
            let rejectTelNo = resp.telNo === undefined ? '' : resp.telNo //  응답 담당자 번호
            let rejectPlcCd = resp.plcCd === undefined ? '' : resp.plcCd //

            let msg = ''

              if (value === '02') { // 발행 요청중
                msg = this.$t('msg.DOIssue.015') // 요청중입니다
                return
              } else if (value === '07' || value === '04') { // 처리중(pending), 거절
                msg = this.$t('msg.ONIM010P020.007') + ' : ' + rejectUsrNm + // 담당자
                      '\n' + this.$t('msg.ONIM010P020.007') + ' ' + this.$t('msg.CMBA100.00159') + ' : ' + rejectTelNo // 담당자 번호
              }

              this.$ekmtcCommon.asyncAlertMessage({ message: msg })
          }
        }).catch(e => {
          console.log(e)
        })
    },
    parseBlStsCd (row) {
      let blStsText = ''
      const eiCatCd = this.params.eiCatCd
      const doStCd = row.doStCd
      const doStsCd = row.doStsCd
      const blStCd = row.blStCd
      const cntrStsCd = row.cntrStsCd
      const elYn = row.elYn
      const cntrCfsYn = row.cntrCfsYn
      const certiYn = row.certiYn
      const doApvStsCd = row.doApvStsCd

      if (eiCatCd === 'O') {
          if (blStCd === '01') {
            blStsText = this.$t('msg.CSBL220_STS.001') //O.B/L 발행
            if (certiYn === 'Y') {
              blStsText += '<br>(CERTI 발행)' // 발급/픽업정보 . O.B/L 발행
            }
          } else if (blStCd === '11') {
            blStsText = this.$t('msg.CSBL220_STS.011') //O.B/L 재발행
            if (certiYn === 'Y' && this.memberDetail.userCtrCd === 'KR') {
              blStsText += '<br>(CERTI 발행)' // 발급/픽업정보 . O.B/L 발행
            }
          } else if (blStCd === '12') {
            blStsText = this.$t('msg.CSBL220_STS.012') //O.B/L 재발행 거절
            if (certiYn === 'Y' && this.memberDetail.userCtrCd === 'KR') {
              blStsText += '<br>(CERTI 발행)' // 발급/픽업정보 . O.B/L 발행
            }
          } else if (blStCd === '13') {
            blStsText = this.$t('msg.CSBL220_STS.013') //O.B/L 재발행 PENDING
            if (certiYn === 'Y' && this.memberDetail.userCtrCd === 'KR') {
              blStsText += '<br>(CERTI 발행)' // 발급/픽업정보 . O.B/L 발행
            }
          } else if (blStCd === '15') {
            blStsText = this.$t('msg.CSBL220_STS.015')//O.B/L 재발행중(요청)
            if (certiYn === 'Y' && this.memberDetail.userCtrCd === 'KR') {
              blStsText += '<br>(CERTI 발행)' // 발급/픽업정보 . O.B/L 발행
            }
          } else if (blStCd === '02') {
            blStsText = this.$t('msg.CSBL220_STS.002') //Surrender 발행
          } else if (blStCd === '03') {
            blStsText = this.$t('msg.CSBL220_STS.003') //S/Waybill 발행
          } else if (blStCd === '04') {
            blStsText = this.$t('msg.CSBL220_STS.004') //S/Waybill 발행중
          } else if (blStCd === '06') {
            blStsText = this.$t('msg.CSBL220_STS.006') //O.B/L 발행중
          } else if (blStCd === '07') {
            blStsText = this.$t('msg.CSBL220_STS.007') //Surrender 처리중
          } else if (blStCd === '14') {
            blStsText = this.$t('msg.CSBL220_STS.014') //S/Waybill 승인
          } else if (blStCd === '16') {
            blStsText = this.$t('msg.CSBL220_STS.016') //O.B/L 승인
          } else if (blStCd === '17') {
            blStsText = this.$t('msg.CSBL220_STS.017') //Surrender 승인
          } else if (blStCd === '42') {
            blStsText = this.$t('msg.CSBL220_STS.042') //Surrender 취소
          } else if (blStCd === '46') {
            blStsText = this.$t('msg.CSBL220_STS.046') //S/Waybill 취소
          } else if (blStCd === '88') {
            blStsText = this.$t('msg.CSBL220_STS.088') //발행거절
          } else if (blStCd === '89') {
            blStsText = 'Pending'
          } else if (blStCd === '99') {
            // 쿼리 적용 기다림.
            if (this.memberDetail.userCtrCd !== 'JP' && cntrStsCd === 'N' && elYn === 'N') {
              blStsText = this.$t('msg.CSBL220_STS.099') //신청불가
            } else if (this.memberDetail.userCtrCd !== 'JP' && elYn === 'N') {
              blStsText = this.$t('msg.CSBL220_STS.099') //신청불가
            } else if (this.memberDetail.userCtrCd !== 'JP' && cntrStsCd === 'N' && cntrCfsYn === 'N') {
              blStsText = this.$t('msg.CSBL220_STS.099') //신청불가
            } else {
              blStsText = this.$t('msg.SETT010T010.136') // 신청가능
            }
          }
        } else {
          if (['JP', 'VN', 'TH', 'MY', 'PH'].indexOf(this.memberDetail.userCtrCd) >= 0 && doApvStsCd !== '') {
            if (doStsCd === '1') {
              blStsText = this.$t('msg.SETT010T010.104') //발행완료
            } else {
              if (doApvStsCd === '02') { // 요청
                blStsText = this.$t('msg.CSBL200.057') //발행요청
              } else if (doApvStsCd === '03') { // 승인
                blStsText = this.$t('msg.SETT010T010.104') //발행완료
              } else if (doApvStsCd === '04') {
                blStsText = this.$t('msg.CSBL220_STS.088') //발행거절
              } else if (doApvStsCd === '07') {
                blStsText = 'Pending'
              } else { // 취소
                blStsText = this.$t('msg.SETT010T010.136') //신청가능
              }
            }
          } else {
            if (doStCd === '01') {
              blStsText = this.$t('msg.SETT010T010.136') //신청가능
            } else if (doStCd === '02' || doStCd === '03' || doStCd === '04' || doStCd === '05' || doStCd === '06' || doStCd === '14') {
              blStsText = this.$t('msg.CSBL220_STS.099') //신청불가
            } else if (doStCd === '07') {
              blStsText = this.$t('msg.CSBL200.057') //발행요청
            } else if (doStCd === '08') {
              blStsText = this.$t('msg.CSBL220_STS.088') //발행거절
            } else if (doStCd === '09') {
              blStsText = 'Pending'
            } else if (doStCd === '10' || doStCd === '11' || doStCd === '12' || doStCd === '13') {
              blStsText = this.$t('msg.SETT010T010.104') //발행완료
            }
          }
        }
      return blStsText
    },
    // DO발행일 경우 계산서 체크하기 전에 Status 체크를 먼저 하도록 변경
    async statusValidationCheck (checkList, type, fnType) {
      let i = 0
      let statusValid = true

      if (fnType === 'DO_ISSUE') {
        checkList.some(idx => {
          const tmp = provider.getJsonRows(idx, idx)
          console.log('statusValidationCheck checkList tmp >>> ', tmp)

          if (tmp[0].doStCd === '01' || tmp[0].doStCd === '07' || tmp[0].doStCd === '08' || tmp[0].doStCd === '10') {
            statusValid = true
          } else {
            const tmp2 = provider.getJsonRows(idx, idx)
            if (tmp2.length > 0) {
              if (this.params.eiCatCd === 'O') {
                this.detail = tmp2[0]
                this.customComponent = 'CalcIssueBlMsgPop'
              } else {
                this.detail = tmp2[0]
                this.customComponent = 'CalcIssueDoMsgPop'
              }
              console.log('---openStatusMsgReason---')
              console.log(this.detail)
              console.log('---/openStatusMsgReason---')

              this.isShowPop = true
              $('#layerPop > .popup_dim').fadeIn()
              $('body,html').css('overflow', 'hidden')
            }

            statusValid = false
            return true
          }

          i++
        })
      }

      return statusValid
    },
    // Invoice 발행 및 출력 버튼 호출(수출, 수입 관계없이 발행 - Invoice 발행 전에는 발행하고 출력)
    async getInvoiceInfo4 (idx) {
      this.invoiceData = []
      let blList = []
      let collectBl = []
      this.auxInvoiceData = []

      const tmp = provider.getJsonRows(idx, idx)
      console.log(tmp)

      if (tmp.length > 0) {
        const vo = tmp[0]

        // Collect BL 일때, INVOICE 출력 가능 경우 조건 추가
        if (this.params.eiCatCd === 'I') {
            const isBlExist = blList.findIndex(v => v.blNo === vo.blNo && v.invIssNo === vo.invIssNo)
            if (isBlExist === -1) {
              if (this.params.dtKnd === 'BL') {
                  if (vo.actShprCstEnm === '**') {
                    if (vo.frtPncCdDo === 'P') { // prepaid BL
                      blList.push(vo)
                    } else if (vo.frtPncCdDo === 'C') { // collect BL
                      if (vo.authImInv === 'A') {
                          blList.push(vo)
                      } else {
                          collectBl.push(vo)
                      }
                    }
                  } else {
                    blList.push(vo)
                  }
              } else {
                if (vo.frtPncCdDo === 'P') { // prepaid BL
                    blList.push(vo)
                } else if (vo.frtPncCdDo === 'C') { // collect BL
                  if (vo.authImInv === 'A') {
                      blList.push(vo)
                  } else {
                      collectBl.push(vo)
                  }
                }
              }
            }
        } else {
          const isBlExist = blList.findIndex(v => v.blNo === vo.blNo && v.invIssNo === vo.invIssNo)
          if (isBlExist === -1) {
            blList.push(vo)
          }
        }
      }

      console.log('blList @@@@@@ ', blList)

      blList.forEach(v => {
        const tt = {
          invIssNo: v.invIssNo ? v.invIssNo : '',
          blNo: v.blNo,
          invRno: v.invRno ? v.invRno : '',
          invRno1: v.invRno1 ? v.invRno1 : '',
          index: v.reqCnt ? v.reqCnt : 0, // 없으면 0
          // frtPncCd: v.frtPncCd
          frtPncCd: v.frtPncCdDo
        }
        if (this.memberDetail.userCtrCd !== 'ID') {
          if (v.frtCd === 'Freight') {
            if (this.params.dtKnd === 'BL' && this.params.eiCatCd === 'I') {
                this.invoiceData.push(tt)
            } else {
              this.invoiceData.push(tt)
            }
          }
        } else {
          this.invoiceData.push(tt)
        }

        const tempAuxBl = {
          invIssNo: v.invIssNo ? v.invIssNo : '',
          blNo: v.blNo,
          invRno: v.invRno ? v.invRno : '',
          index: v.reqCnt ? v.reqCnt : 0, // 없으면 0
          // frtPncCd: v.frtPncCd
          frtPncCd: v.frtPncCdDo
        }

        if (v.podCtrCd === 'ID' || v.polCtrCd === 'ID') {
          this.auxInvoiceData.push(tempAuxBl)
        }
      })

      let invoiceData = []
      let tmpInvoiceData = []

      if (this.invoiceData.length > 0) {
        // console.log('대상 : ', this.invoiceData)

        await this.recalculateFreightBillInfo()

        await this.auxInvoiceCreate()

        let chkData = this.checkInvoiceData(this.invoiceData)

        if (chkData) {
          this.invoiceData.forEach(i => {
            const blNo = i.blNo
            tmpInvoiceData.push(blNo)
          })

          tmpInvoiceData = [...new Set(tmpInvoiceData)]

          for (var i = 0; i < tmpInvoiceData.length; i++) {
            invoiceData.push({ blNo: tmpInvoiceData[i], remark: '' })
          }

          this.combineInvoicePopCall(invoiceData, this.params.eiCatCd)
        } else {
          this.openAlert(this.$t('msg.DOIssue.003')) // Invoice를 출력할 B/L이 없습니다.
        }
      } else {
        if (this.params.dtKnd === 'BL' && this.params.eiCatCd === 'I') {
          this.openAlert(this.$t('msg.ADD20220219.002')) //'권한이 없는 B/L 입니다.'
        } else {
          this.openAlert(this.$t('msg.DOIssue.003')) // Invoice를 출력할 B/L이 없습니다.
        }
      }
    },
    // PayRejectCont팝업
    openPayRejectCont (e, inx, payIdx) {
      e.preventDefault()
      if (inx > -1) {
        const tmp = provider.getJsonRows(inx, inx)
        if (tmp.length > 0 && tmp[0].payRejectCont != null && tmp[0].payRejectCont !== '') {
          if (this.params.eiCatCd === 'I' && this.memberDetail.userCtrCd !== 'KR') {
            this.openAlert(tmp[0].payRejectCont.split('|')[payIdx]) // Invoice를 출력할 B/L이 없습니다.
          }
        }
      }
    },
    // PayPendingCont팝업
    openPayPendingCont (e, inx, payIdx) {
      e.preventDefault()
      if (inx > -1) {
        const tmp = provider.getJsonRows(inx, inx)
        if (tmp.length > 0 && tmp[0].payPendingCont != null && tmp[0].payPendingCont !== '') {
          if (this.params.eiCatCd === 'I' && this.memberDetail.userCtrCd !== 'KR') {
            this.openAlert(tmp[0].payPendingCont.split('|')[payIdx]) // Invoice를 출력할 B/L이 없습니다.
          }
        }
      }
    },
    async printReceipt () {
      const checkedItems = gridView.getCheckedRows(true)
      this.popupReceiptParams.CalcIssueReceiptPrintPop.items = []

      if (!checkedItems.length) {
        this.openAlert(this.$t('msg.ADD20220219.008')) // BL을 선택해주세요.
      } else {
        let blArr = []
        let voArr = []

        checkedItems.forEach(idx => {
          const tmp = provider.getJsonRows(idx, idx)
          voArr.push(tmp)

          let isExist = false
          for (let bl of blArr) {
            if (bl.blNo === tmp[0].blNo) {
              isExist = true
            }
          }
          if (!isExist) {
            blArr.push(tmp[0])
          }

          // GA dataLayer 추가
          window.dataLayer.push({
            event: 'set_popup_info',
            popup_booking_no: tmp[0].bkgNo,
            popup_bl_no: tmp[0].blNo
          })
        })

        if (this.params.dtKnd === 'BL' && this.params.eiCatCd === 'I' && !this.isPermitDo(voArr, 'arr')) {
          this.openAlert(this.$t('msg.ADD20220219.002')) //'권한이 없는 B/L 입니다.'
          return
        }

        const params = {
          blList: blArr
        }

        await importSvc.findAIreportReceipt(params).then(res => {
          // TODO : check test value
          // this.popupReceiptParams.CalcIssueReceiptPrintPop.testUsrNo = 'KONOIKE01'
          // this.popupReceiptParams.CalcIssueReceiptPrintPop.testCtrCd = 'ID'
          const resList = res.data

          if (resList.length === 0) {
            this.openAlert(this.$t('msg.DOIssue.027')) // 'Receipt 조회 대상 아닌 데이터 메세지 / Not Available Now.'
            return
          }

          for (const item of resList) {
            const initObj = {
              rcvRno: item.rcvRno,
              prtRvstNo: item.prtRvstNo,
              cstNo: this.memberDetail.cstCd,
              // cstNo: 'KTII00',
              cstNm: item.cstNm
            }

            this.popupReceiptParams.CalcIssueReceiptPrintPop.items.push(initObj)
          }
          this.openReceiptPopup('CalcIssueReceiptPrintPop')
        }).catch(err => {
          console.log(err)
        })
      }
    },
    openReceiptPopup (compNm) {
      if (this.receiptComponent !== null) {
        this.receiptComponent = null
        this.$ekmtcCommon.layerClose()
      }
      this.infoData = this.popupReceiptParams[compNm]
      this.receiptComponent = compNm

      this.$ekmtcCommon.layerOpen('.sample_popup_list')
    },
    closePopup (obj) {
        this.receiptComponent = null
        this.$ekmtcCommon.layerClose('.sample_popup_list')
    },
    changeCalcEiCatCd () {
      this.$store.commit('auth/updateCalcEiCatCd', this.params.eiCatCd)
    },
    openTp2Popup (compNm, params) {
      this.popupTp2Params = params
      this.popupTp2Component = compNm
      this.$ekmtcCommon.layerOpen('.calc_issue_fare_regis_req_popup')
    },
    closeTp2Popup () {
      this.popupTp2Params = {}
      this.popupTp2Component = null
      this.popupTp2Callback = ''
      this.$ekmtcCommon.layerClose('.calc_issue_fare_regis_req_popup')
    },
    invoiceRequest (blNo, idx) {
      this.openTp2Popup('CalcIssueFareRegisReqPop', { blNo: blNo, index: idx, invRno: '', rtnCode: '' })
    },
    confirmTp2Popup () {
      provider.setValue(this.popupTp2Params.index, 'reqCnt2', '1')
      this.popupTp2Params = {}
      this.popupTp2Component = null
      this.popupTp2Callback = ''
      this.$ekmtcCommon.layerClose('.calc_issue_fare_regis_req_popup')
    },
    changeCurDmdCatCd () {
      if (this.params.eiCatCd === 'I') {
        this.curDmdCatCd = this.$store.state.settle.imDmdCatCd
      } else {
        this.curDmdCatCd = this.$store.state.settle.etDmdCatCd
      }
    },
    async recalculateFreightBillInfo () {
      let blArrStr = ''

      for (let i = 0; i < this.invoiceData.length; i++) {
        if (blArrStr !== '') {
          blArrStr += ','
        }

        blArrStr += this.invoiceData[i].blNo
      }

      let req = {
        blNo: blArrStr
      }

      await CalcIssue.recalculateFreightBillInfo(req).then(response => {
        console.log('Success')
      }).catch(e => {
        console.log(e)
      })
    },
    async auxInvoiceCreate () {
      let blArrStr = ''

      if (this.auxInvoiceData.length > 0) {
        for (let i = 0; i < this.auxInvoiceData.length; i++) {
          if (blArrStr !== '') {
            blArrStr += ','
          }

          blArrStr += this.auxInvoiceData[i].blNo
        }

        let req = {
          blNo: blArrStr,
          eiCatCd: this.params.eiCatCd
        }

        await CalcIssue.auxInvoiceCreate(req).then(response => {
          console.log('Success')
        }).catch(e => {
          console.log(e)
        })
      }
    },
    checkSelectCtr () {
      if (this.selCtr !== '') {
        if (this.selPo === 'POL') {
          this.gridList = this.rstList.filter(vo => vo.polCtrCd === this.selCtr)
        } else if (this.selPo === 'POD') {
          this.gridList = this.rstList.filter(vo => vo.podCtrCd === this.selCtr)
        }
        this.total = this.getTotalCnt(this.gridList)
        provider.setRows(this.gridList)
      } else {
        this.total = this.getTotalCnt(this.rstList)
        provider.setRows(this.rstList)
      }
    },
    handleOnDoReqBtnClick () {
      this.memberDetail.userCtrCd !== 'ID' ? this.fnAction('DO_REQUEST') : this.openPopup('PaymentRegPop', 'PaymentRegPop')
    },
    async couponUseChk () {
      let blArrStr = ''

      for (let i = 0; i < this.invoiceData.length; i++) {
        if (blArrStr !== '') {
          blArrStr += ','
        }

        blArrStr += this.invoiceData[i].blNo
      }

      let req = {
        blNo: blArrStr
      }

      await CalcIssue.couponList(req).then(response => {
        this.couponList = response.data.couponList
      }).catch(e => {
        console.log(e)
      })
    }
  }
}

</script>
<style>
.multi-line-css{
  white-space: pre;
}
</style>
